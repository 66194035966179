import React from 'react';
import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import PasswordRecovery from '../../components/PageComponents/PasswordRecovery';
import Footer from '../../components/PageComponents/Footer';

import './styles.css';

const PasswordRecoveryPage: React.FC = () => {
  
  const gaEventTracker = useAnalyticsEventTracker('Recuperar-senha Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/recuperar-senha', 'Recuperar-senha Page');
  }, []);
  
  return (
    <div id="password-recovery-page" className="container">     
      <PasswordRecovery />
      <Footer />          
    </div>
  );
}

export default PasswordRecoveryPage;
