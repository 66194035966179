import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import UserPanel from '../../components/PageComponents/UserPanel';
import OrderList from '../../components/PageComponents/OrdersList';
import Footer from '../../components/PageComponents/Footer';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import { mainStyle } from './styles';

const OrdersPage: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Pedidos Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/pedidos', 'Pedidos Page');   
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      <OrderList />
      <Footer />
    </Box>
  );
}

export default OrdersPage;
