import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import UserPanel from '../../components/PageComponents/UserPanel';
import UserProfile from '../../components/PageComponents/UserProfile';
import Footer from '../../components/PageComponents/Footer';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import { mainStyle } from './styles';

const UserProfilePage: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Perfil-do-usuario Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/perfil', 'Perfil-do-usuario Page');   
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      <UserProfile />
      <Footer />
    </Box>
  );
}

export default UserProfilePage;
