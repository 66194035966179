import React, { useEffect } from 'react';
import Box from '@mui/system/Box';
import { useNavigate } from 'react-router-dom';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import UserPanel from '../../components/PageComponents/UserPanel';
import Main from '../../components/PageComponents/Main';
import Footer from '../../components/PageComponents/Footer';

import { useAppDataHandler } from '../../contexts/appData';

import { mainStyle } from './styles';

const MainPage: React.FC = () => {
  const { loadMeData, setRouteTo, navigateTo } = useAppDataHandler();
  const navigate = useNavigate();

  const gaEventTracker = useAnalyticsEventTracker('Inicio Page');
    
  useEffect(() => {
    gaEventTracker.sendPageView('/', 'Inicio Page');
    loadMeData();   
    if (navigateTo !== '/') navigate(navigateTo);   
    setRouteTo('/');
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />     
      <Main />
      <Footer />
    </Box>
  );
}

export default MainPage;
