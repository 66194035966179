
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic'

import Routes from './routes';

import { ErrorProvider } from './contexts/error';
import { AuthProvider } from './contexts/auth';
import { AppDataProvider } from './contexts/appData';
import { LogProvider } from './contexts/logs';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.FADE
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId='858239530326-lrb0ltjjgl57ovljvu327jbooii1omqe.apps.googleusercontent.com'>
    <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>
        <ErrorProvider>
          <AuthProvider>
            <AppDataProvider>
              <LogProvider>
                <Routes />
              </LogProvider>              
            </AppDataProvider>                        
          </AuthProvider>
        </ErrorProvider>
      </AlertProvider>
    </React.StrictMode> 
  </GoogleOAuthProvider>  
);
