import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '90rem',
    md: '89rem',
    lg: '86rem',
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',      
  alignItems: 'center', 
};

export const headerStyle: SxProps = {
  width: '100%',
  height: '20rem',  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const logoStyle: SxProps = {
  width: {
    xs: '20rem',      
    md: '26rem',
  },
  height: {
    xs: '12rem',      
    md: '15.5rem',
  },
  marginBottom: '1rem',
};

export const h3Style: SxProps = {
  fontSize: '3rem',
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textShadow: '2px 2px var(--color-text-dark-gray)',         
};

export const strongStyle: SxProps = {
  width: '90%',
  fontSize: '1.8rem',
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',
  textAlign: 'center'
};

export const formStyle: SxProps = {
  width: {
    xs: '35rem',   
    md: '50rem',
    lg: '60rem',
  },
  height: { 
    xs: '60rem',
    md: '58rem',
    lg: '56rem',   
  },  
  display: 'flex',
  flexDirection: 'column',   
  justifyContent: 'center',   
  alignItems: 'center',
  borderRadius: '2rem',
  background: 'var(--color-background)',
  marginTop: '4rem',
};

export const formBoxStyle: SxProps = {
  width: {
    xs: '32rem',    
    md: '46rem',
    lg: '55rem',
  },
  height: { 
    xs: '35rem',   
  }, 
  display: 'flex',
  flexDirection: 'column',  
  justifyContent: 'center',    
  alignItems: 'center',
  marginTop: '1.5rem'  
};

export const inputStyle: React.CSSProperties = {
  width: '100%',
  height: '4rem',  
  background: 'var(--color-background-white)',  
  borderStyle: 'solid',
  borderColor: '#bebebe',
  borderRadius: '0.4rem',
  padding: '1rem',
  borderWidth: 'thin',
};

export const textAreaStyle: React.CSSProperties = {
  width: '100%',
  height: '25rem',
  borderStyle: 'solid',
  background: '#FFFFFF',
  padding: '1rem',
  borderRadius: '0.3rem',
  borderColor: '#bebebe',
}

export const inputBoxStyle: SxProps = {
  width: {
    xs: '32rem',   
    md: '46rem',
    lg: '55rem',
  },
  height: {
    xs: '8rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',  
};

export const textAreaBoxStyle: SxProps = {
  width: {
    xs: '32rem',    
    md: '46rem',
    lg: '55rem',
  },
  height: {
    xs: '20rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start', 
  marginTop: '0.7rem', 
};

export const buttonBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    //sm: '40rem',
    //lg: '86rem',
    md: '46rem',
    lg: '55rem',
  },
  height: '4rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '2rem',  
};

export const buttonStyle: SxProps = {
  width: {
    xs: '12rem',
    //sm: '14rem',
  },
  height: '3rem',
  backgroundImage: 'linear-gradient(to right, var(--color-button-dark), var(--color-button-light))',
  color: 'var(--color-button-text-light)',
  border: 0,
  borderRadius: '0.4rem',  
  cursor: 'pointer',  
  fontSize: '1.5rem',
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-light-hover)' },  
};

export const loaderStyle: SxProps = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-54%, -40%)',
  width: { 
    xs: '20rem',     
  },
  height: {
    xs: '20rem',      
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',    
};
