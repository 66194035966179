import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '150rem',
    sm: '132rem',
    lg: '122rem',   
  },  
  display: 'flex',
  flexDirection: {
    xs: 'column',   
  },
  justifyContent: {
    xs: 'center',   
  },      
  alignItems: 'center', 
};

export const contentStyle: SxProps = {
  width: {
    xs: '35rem',
    sm: '44rem',
    lg: '95rem',
  },
  height: { 
    xs: '140rem',
    sm: '120rem', 
    lg: '110rem', 
  },  
  display: 'flex',
  flexDirection: 'column',    
  justifyContent: 'center',   
  alignItems: 'center',
  borderRadius: '2rem',
  background: 'var(--color-background)',
};

export const h2Style: SxProps = {
  fontSize: {
    xs: '2rem',
    lg: '2.4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',
  marginBottom: '1rem',
};

export const olStyle: SxProps = {
  width: {
    xs: '28rem',
    sm: '35rem',
    lg: '75rem',
  },
  height: {
    xs: '102rem',
    sm: '82rem',
    lg: '66rem',
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

export const liStyle: SxProps = {
  fontSize: {
    xs: '1.4rem',
    lg: '1.7rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',
  textAlign: 'start',
  marginBottom: '1rem' 
};

export const pStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '37rem',
    lg: '80rem',
  },
  fontSize: {
    xs: '1.4rem',
    lg: '1.8rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',
  textAlign: 'center',
  marginBottom: {
    xs: '1.5rem',
    lg: '2.5rem',
  }, 
};

export const strongStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '37rem',
    lg: '80rem',
  },
  fontSize: {
    xs: '1.9rem',    
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',
  textAlign: 'center',
  marginBottom: {
    xs: '1rem',
    lg: '3rem',
  }, 
};

export const buttonStyle: SxProps = {
  width: {
    xs: '17rem',    
    lg: '22rem',
  },
  height: {
    xs: '3rem',
    lg: '4rem',
  },  
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text-black)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: {
    xs: '1.5rem',    
    lg: '1.6rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',    
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  marginTop: '2.5rem', 
  '&:hover': { color: 'var(--color-button-text-black-hover)' },  
};

export const linkStyle: SxProps = {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
