import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import UserPanel from '../../components/PageComponents/UserPanel';
import Faq from '../../components/PageComponents/Faq';
import Footer from '../../components/PageComponents/Footer';


import { mainStyle } from './styles';

const FaqPage: React.FC = () => {  

  const gaEventTracker = useAnalyticsEventTracker('FAQ Page');
    
  useEffect(() => {
    gaEventTracker.sendPageView('/perguntas-mais-frequentes', 'FAQ Page');    
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />     
      <Faq />
      <Footer />
    </Box>
  );
}

export default FaqPage;
