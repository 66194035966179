import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import UserPanel from '../../components/PageComponents/UserPanel';
import Community from '../../components/PageComponents/Community';
import Footer from '../../components/PageComponents/Footer';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import { mainStyle } from './styles';

const CommunityPage: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Comunidade Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/comunidade', 'Comunidade Page');   
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />
      <Community />
      <Footer />
    </Box>
  );
}

export default CommunityPage;
