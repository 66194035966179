import React, { useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";
import { useAlert, positions } from 'react-alert'
import { useNavigate } from 'react-router-dom';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import Box from '@mui/system/Box';

import { TextInput, SelectInput } from '../../Form';

import { useErrorHandler } from '../../../contexts/error';

import { useAppDataHandler } from '../../../contexts/appData';

import api from '../../../services/api';

import logo from '../../../assets/images/logos/logo.png';

import { 
  mainStyle,
  borderBoxStyle,
  headerStyle,
  h3Style,
  formStyle,
  formBoxStyle,
  inputBoxStyle,
  profileInput,
  profileSelect,
  profileSelectCountries,
  nameBoxStyle,
  birthdayBoxStyle,
  documentBoxStyle,
  emailBoxStyle,
  phoneBoxStyle,
  whatsappBoxStyle,
  addressBoxStyle,
  numberBoxStyle,
  complementBoxStyle,
  neighborhoodBoxStyle,
  cityBoxStyle,
  stateBoxStyle,
  countryBoxStyle,
  zipcodeBoxStyle,
  buttonBoxStyle,
  buttonStyle,
  logoBoxStyle,
  logoImgStyle,
  aStyle,
} from './styles';

interface FormData {
  name: string,        
  birthday: string,
  document: string,
  email: string,
  phoneNumber: string,
  whatsapp: string,      
  address: string,
  number: string,
  complement: string,
  neighborhood: string,
  city: string,
  state: string,
  zipcode: string,
  country: string, 
};

const ProfileForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);  
  const [loader, setLoader] = useState(true);
  const [status, setStatus] = useState('');
  const [ countries, setCountries ] = useState<any>(null);
  const [ country, setCountry ] = useState<any>('Brasil');
  const [ uf, setUf ] = useState<any>(null);
  const { handleError } = useErrorHandler();
  const alert = useAlert()
  const navigate = useNavigate(); 

   const { loadMeData, user, userProfile } = useAppDataHandler();  

  useEffect(() => {
    async function loadData() {
      let resp = await api.get('/utils/countries');
      if (resp) setCountries(resp.data);
      resp = await api.get('/utils/states');
      if (resp) setUf(resp.data);      
      if (!user) {       
        await loadMeData();
      } 
      setLoader(false);    
    }    
    loadData();       
  }, []);

  useEffect(() => {
    handleLoadForm();
  }, [user, userProfile]);

  useEffect(() => {   
    handleLoadForm();
  }, [countries, uf]);

  const handleLoadForm = () => {    
    const initialData = {
      name: user?.name,        
      birthday: userProfile?.birthday,
      document: userProfile?.document,
      email: user?.email,
      phoneNumber: userProfile?.phoneNumber,
      whatsapp: userProfile?.whatsapp,     
      address: userProfile?.address,
      number: userProfile?.number,
      complement: userProfile?.complement,
      neighborhood: userProfile?.neighborhood,
      city: userProfile?.city,
      state: userProfile?.state,
      zipcode: userProfile?.zipcode,
      country: userProfile?.country,  
    };
    formRef.current?.setData(initialData);
    setStatus(userProfile?.status || 'pending');
  }; 

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({                
        name: Yup.string().required('Nome é obrigatório'),        
        birthday: Yup.string().optional(),       
        document: Yup.string().matches(/^[0-9]*$/, 'Apenas números'),//.required('CPF é obrigatório'),
        phoneNumber: Yup.string().optional(),
        whatsapp: Yup.string().optional(),          
        address: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Endereço é obrigatório'),
        number: Yup.string().required('Obrigatório'),
        complement: Yup.string().optional(),
        neighborhood: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Bairro é obrigatório'),
        city: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Cidade é obrigatório'),
        state: Yup.string().required('UF é obrigatório'),
        zipcode: Yup.string().min(3, 'Mínimo de 3 caracteres').required('CEP é obrigatório'),
        country: Yup.string().required('País é obrigatório'),                
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      if (!cpfValidator.isValid(data.document) && data.country === 'Brasil') {       
        throw new Error('cpf');        
      }
      try {       
        await api.put('/profiles/my-profile', data);     
        alert.show('Perfil atualizado com sucesso!', 
          { type: 'success', 
            timeout: 5000,
            position: positions.MIDDLE,
          });
        setStatus('completed');
      } catch (error: any) {       
        handleError(error.message);
      }      
      formRef.current?.setErrors({});      
      setLoader(false);
      await loadMeData();
      navigate(-1);  
    } catch (err: any) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {          
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);
        });
        formRef.current?.setErrors(validationErrors);              
      }      
      if (err.message === 'cpf') {        
        formRef.current?.clearField('document');  
        formRef.current?.setErrors({ 'document': 'O CPF é inválido' });  
      }
      setLoader(false);      
    }   
  };

  const handleClick = async () => {   
    navigate(-1);      
  };

  const handleSelect = () =>{
    const value = formRef.current?.getFieldValue('country');
    setCountry(value);    
  };

  return (
    <Box sx={mainStyle}>
      <Box sx={borderBoxStyle}>
        <Box sx={headerStyle}>
          <Box component='h3' sx={h3Style}>COMPLETE O CADASTRO</Box> 
          <Box component='h3' sx={h3Style}>PARA CONTINUAR</Box>               
        </Box>          
        <Box sx={formStyle}>
          <Form ref={formRef} onSubmit={handleSubmit} placeholder=''>          
            <Box sx={formBoxStyle}>
              <Box>
                <Box sx={nameBoxStyle}>
                  <p>Nome Completo *</p>
                  <TextInput styles={profileInput} name="name" placeholder='Nome Completo' type='text' />
                </Box>
                <Box sx={inputBoxStyle}>
                  <Box sx={birthdayBoxStyle}>
                    <p>Data de Nascimento</p>
                    <TextInput styles={profileInput} name="birthday" placeholder="Data de nascimento" type='text' />
                  </Box>
                  <Box sx={documentBoxStyle}>
                    <p>CPF (Apenas números) *</p>
                    <TextInput styles={profileInput} name="document" placeholder="Número do CPF" type='text' /> 
                  </Box>
                </Box>
                <Box sx={emailBoxStyle}>                     
                  <p>Email (Não pode ser alterado)</p>
                  <TextInput styles={profileInput} name="email" type='email' disabled={true}/>
                </Box>
                <Box sx={inputBoxStyle}>
                  <Box sx={phoneBoxStyle}>
                    <p>Telefone de Contato</p>
                    <TextInput styles={profileInput} name="phoneNumber" type='text' />
                  </Box>
                  <Box sx={whatsappBoxStyle}>
                    <p>Whatsapp</p>
                    <TextInput styles={profileInput} name="whatsapp" type='text' /> 
                  </Box>
                </Box>
              </Box>                

              <Box>
                <Box sx={inputBoxStyle}>
                  <Box sx={addressBoxStyle}>
                    <p>Endereço *</p>
                    <TextInput styles={profileInput} name="address" placeholder='Avenida, Rua, Praça, etc' type='text' />
                  </Box>
                  <Box sx ={numberBoxStyle}>
                    <p>Número *</p>
                    <TextInput styles={profileInput} name="number" type='text' /> 
                  </Box>
                </Box> 
                <Box sx={complementBoxStyle}>
                  <p>Complemento</p>
                  <TextInput styles={profileInput} name="complement" type='text' />
                </Box>
                <Box sx={neighborhoodBoxStyle}>
                  <p>Bairro *</p>
                  <TextInput styles={profileInput} name="neighborhood" placeholder='Bairro' type='text' />  
                </Box>
                <Box sx={cityBoxStyle}>
                  <p>Cidade *</p>
                  <TextInput styles={profileInput} name="city" type='text' />
                </Box>
                <Box sx={inputBoxStyle}>
                  {
                    uf ?
                      country === 'Brasil' ?
                        <Box sx={stateBoxStyle}>
                          <p>UF *</p>
                          <SelectInput styles={profileSelect} name='state' options={uf}/>
                        </Box>
                      :
                        <Box sx={stateBoxStyle}>
                          <p>Estado *</p>
                          <TextInput styles={profileInput} name='state' type='text'/>
                        </Box>
                    :
                    <Box sx={stateBoxStyle}>
                      <p>UF *</p>
                      <TextInput styles={profileInput} name='state' type='text'/>
                    </Box>
                  }
                  <Box sx={zipcodeBoxStyle}>  
                    <p>CEP *</p>
                    <TextInput styles={profileInput} name="zipcode" type='text' />  
                  </Box>
                </Box>              
                <Box sx={inputBoxStyle}>
                  {
                    countries ?
                    <Box sx={countryBoxStyle} onChange={handleSelect}>
                      <p>País *</p>
                      <SelectInput styles={profileSelectCountries} name="country" options={countries}/>  
                    </Box>
                    :
                    <Box sx={countryBoxStyle}>
                      <p>País *</p>
                      <TextInput styles={profileInput} name="country" type='text'/>  
                    </Box>
                  }                
                </Box>
              </Box> 
            </Box>
            <Box sx={buttonBoxStyle}>
              <Box component='button' sx={buttonStyle} type='button' onClick={handleClick}>Voltar</Box>
              {
                loader ?
                  <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />
                :
                  <Box sx={logoBoxStyle}>
                    <Box component='a' sx={aStyle} href="https://escoladenumerologia.com.br" target='_blank' rel='noreferrer noopener' onClick={()=> {}}>
                      <Box component='img' sx={logoImgStyle} src={logo} alt='logo'/>
                    </Box>          
                  </Box>
              }
              <Box component='button' sx={buttonStyle} type='submit'>Salvar</Box>
            </Box>          
          </Form>          
        </Box>         
      </Box>    
    </Box>
  );
}

export default ProfileForm;
