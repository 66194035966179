import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";
import { useAlert, positions } from 'react-alert'
import { Link } from 'react-router-dom';

import { TextInput } from '../../../components/Form';

import { useErrorHandler } from '../../../contexts/error';

import { signUp } from '../../../services/auth';

import logo from '../../../assets/images/logos/logo.png';

import './styles.css';

interface FormData {
  name: string,
  email: string,
  password: string, 
}

const SignUpEmail= () => {
  const formRef = useRef<FormHandles>(null);  
  const [loader, setLoader] = useState(false);
  const { handleError } = useErrorHandler();
  const alert = useAlert() 

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({                
        name: Yup.string().required('Nome é obrigatório'),
        email: Yup.string().email('Digite um email válido').required('Email é obrigatório'),
        password: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Senha é obrigatória'),            
      });
      await schema.validate(data, {
        abortEarly: false,
      });      
      try {       
        await signUp(data.name, data.email, data.password);
        alert.show('Cadastro efetuado. Você receberá um email com um link para validação!', 
          { type: 'success', 
            timeout: 10000,
            position: positions.MIDDLE,
          });
      } catch (error: any) {       
        handleError(error.message);
      }      
      formRef.current?.setErrors({});
      reset();
      setLoader(false);      
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);
        });
        formRef.current?.setErrors(validationErrors);              
      }
      setLoader(false);      
    }   
  };

  return (
    <div className="signup-email">
      <div className="signup-email-content">
        {
          loader &&
          <div id="loader">
            <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={100} />
          </div> 
        } 
        <div className="signup-email-main">
          <h2>CADASTRO</h2>                
          <div className="form-box">
            <Form ref={formRef} onSubmit={handleSubmit} placeholder=''>
              <p>Nome Completo</p>
              <TextInput className='signup-input' name="name" placeholder="Seu nome" type='text' />
              <p>Email</p>
              <TextInput className='signup-input' name="email" placeholder="Seu email" type='email' />
              <p>Senha</p>
              <TextInput className='signup-input' name="password" placeholder="Sua senha" type='password' />              
              <p id='termos'>Ao clicar para se cadastrar, você concorda com os <Link to='/termos-de-uso-e-privacidade'>Termos de Uso</Link></p>
              <div className="button-box">
                <Link to='/'>
                  <strong>Voltar</strong>
                </Link>               
                <button type="submit">Cadastrar</button>
              </div>
            </Form>            
            <div className='logo'>
              <a href="https://escoladenumerologia.com.br" target='_blank' rel='noreferrer noopener' onClick={()=> {}}>
                <img src={logo} alt='logo'/>
              </a>          
            </div>
          </div> 
        </div> 
      </div>
    </div>
  );
}

export default SignUpEmail;
