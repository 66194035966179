import React, { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid/Grid';
import Loader from "react-js-loader";

import { useAuth } from '../../../contexts/auth';

import api from '../../../services/api';

import ILesson from '../../../data/interfaces/ILesson';

import Lesson from './Lesson';

import {
  mainStyle,
  h3Style,
  contentStyle,  
} from './styles';

const LessonsList: React.FC = () => {  
  const [ lessons, setLessons ] = useState<Array<any>|[]>([]); 
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {      
      const response = await api.get('/lessons');
      if (response.data) {
        setLessons(response.data.lessons);       
      }      
    } catch (error) {
      console.log('unauthorized')
    }
  };

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/lessons');        
        if (response.data) {          
          setLessons(response.data.lessons);         
        }            
      } catch (error: any) {       
        if (error.message === 'unauthorized') await handleRefreshToken();
        reloadData();                      
      }     
    }    
    loadData();    
  }, []);  

  return (
    <Box sx={mainStyle}>
      <Box component='h3' sx={h3Style}>MENTORIAS</Box>         
      <Box sx={contentStyle}>        
        {
          lessons.length > 0 ? 
          <Grid container spacing={{ xs: '2rem', lg: '4rem' }} justifyContent='center' alignItems='center'>
            {lessons.map((l: ILesson) => {             
              return (                
                <Grid  xs='auto' md='auto' lg='auto' key={l.uid}>                  
                  <Lesson lesson={l} key={l.uid}/>                               
                </Grid>
              )
            })}
          </Grid> : 
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} /> 
        }        
      </Box>
    </Box>
  );
};

export default LessonsList;
