import React from 'react';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './assets/styles/global.css';

const TRACKING_ID = 'G-39H0SM7CDD';
//const TRACKING_ID = 'aaaa';
ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => {
  return (
    <div id="app">     
      <Outlet />      
    </div>
  );
}

export default App;
