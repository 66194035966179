import { SxProps } from '@mui/system';

export const toolbarStyle: SxProps = { 
  flexGrow: 1, 
  display: { 
    xs: 'flex', 
    md: 'none'
  },  
};

export const menuStyle: SxProps = {
  display: {
    xs: 'block', 
    md: 'none' 
  },
};

export const menuTypoStyle: SxProps = {
  fontFamily: 'barlow',
  fontWeight: 600,
  fontSize: '1.5rem',
  color: 'var(--color-text-blue)',
  textDecoration: 'none',
};

export const typoStyle: SxProps = {
  ml: -5,
  display: { xs: 'flex', md: 'none' },
  flexGrow: 1,
  fontFamily: 'barlow',
  fontWeight: 700,
  letterSpacing: '.3rem',
  color: 'var(--color-text-white)',
  textDecoration: 'none',
};

export const buttonStyle: SxProps = { 
  my: 2, 
  color: 'white', 
  display: 'block', 
  fontSize: {
    md: '1.5rem',
    lg: '1.8rem',
  }, 
};
