import React, { useEffect } from 'react';

import Footer from '../../components/PageComponents/Footer';
import ProfileForm from '../../components/PageComponents/ProfileForm';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import './styles.css';

const ProfileFormPage: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Completar-cadastro Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/cadastro', 'Completar-cadastro Page');   
  }, []);

  return (
    <div id="user-profile-page" className="container">
      <ProfileForm />     
      <Footer />
    </div>
  );
}

export default ProfileFormPage;
