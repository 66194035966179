import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import UserPanel from '../../components/PageComponents/UserPanel';
import Footer from '../../components/PageComponents/Footer';
import BonusLesson from '../../components/PageComponents/BonusLesson';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import IBonusLesson from '../../data/interfaces/IBonusLesson';

import { 
  mainStyle,
  contentStyle,
  h2Style,
} from './styles';

const BonusLessonPage = () => {
  const [ bonusLesson, setBonusLesson ] = useState<IBonusLesson|null>(null);
  const [ loader, setLoader ] = useState(true);
  
  let { bonusLessonUid } = useParams();
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {     
      const response = await api.get('/lessons/bonus/'+bonusLessonUid);
      if (response.data) {
        setBonusLesson(response.data.bonusLesson);
        setLoader(false);
      }      
    } catch (error) {
      console.log('unauthorized')
    }
  };

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/lessons/bonus/'+bonusLessonUid);
        if (response.data) {
          setBonusLesson(response.data.bonusLesson);
          setLoader(false);
        }             
      } catch (error: any) {
        if (error.message === 'unauthorized') {
          await handleRefreshToken();
          reloadData();     
        } 
        setLoader(false);              
      }     
    }    
    loadData();    
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      {
        bonusLesson ?
          <BonusLesson bonusLesson={bonusLesson}/>    
        :
          loader ?
            <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
          :
            <Box sx={contentStyle}>
              <Box component='h2' sx={h2Style}>Aula bonus não encontrada!</Box>
            </Box>     
      }
      <Footer />
    </Box>
  );
}

export default BonusLessonPage;
