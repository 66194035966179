import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: {
    xs: 'column',    
  },
  justifyContent: {
    xs: 'center',   
  },      
  alignItems: 'center', 
};

export const titleStyle: SxProps = {
  height: '15rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
};

export const faqStyle: SxProps = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
  marginBottom: '8rem',
};

export const accordionStyle: SxProps = {
  width: {
    xs: '35rem',
    sm: '45rem',
    md: '60rem',
    lg: '80rem',
  },  
}

export const h3Style: SxProps = {
  fontSize: '3rem',
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textShadow: '2px 2px var(--color-text-dark-gray)',  
};

export const h4Style: SxProps = {
  fontSize: '2rem',
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textShadow: '2px 2px var(--color-text-dark-gray)',  
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '1.7rem',
    sm: '1.8rem',
    md: '2.0rem',    
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',  
};

export const pStyle: SxProps = {
  fontSize: {
    xs: '1.4rem',
    sm: '1.6rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',  
};
