import React, { createContext, useContext } from "react";

import { useAuth } from '../contexts/auth';

import api from '../services/api';

interface LogContextData {
  createLogLessonView(lessonUid: string): void;
  createLogLessonVideoView(lessonUid: string): void;
  createLogLessonVideoViewFull(lessonUid: string): void;
  createLogCourseView(courseUid: string): void;
  createLogCourseLessonVideoView(courseUid: string, courseLessonUid: string): void;
  createLogCourseLessonVideoViewFull(courseUid: string, courseLessonUid: string): void;
}
type Props = {
  children?: React.ReactNode
};

const LogContext = createContext<LogContextData>({} as LogContextData);

export const LogProvider: React.FC<Props> = ({ children }) => {
  const { handleRefreshToken } = useAuth();

  const createLogLessonView = async (lessonUid: string) => { 
    try {
      await api.post('/logs/lessons/'+lessonUid+'/view');        
      return;       
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      try {      
        await api.post('/logs/lessons/'+lessonUid+'/view');
        return;     
      } catch (error) {
        console.log('unauthorized')
        return;
      }                      
    }     
  };

  const createLogLessonVideoView = async (lessonUid: string) => { 
    try {
      await api.post('/logs/lessons/'+lessonUid+'/video-view');        
      return;       
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      try {      
        await api.post('/logs/lessons/'+lessonUid+'/video-view');
        return;     
      } catch (error) {
        console.log('unauthorized')
        return;
      }                      
    }     
  };

  const createLogLessonVideoViewFull = async (lessonUid: string) => { 
    try {
      await api.post('/logs/lessons/'+lessonUid+'/video-view-full');        
      return;       
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      try {      
        await api.post('/logs/lessons/'+lessonUid+'/video-view-full');
        return;     
      } catch (error) {
        console.log('unauthorized')
        return;
      }                      
    }     
  };

  const createLogCourseView = async (courseUid: string) => { 
    try {
      await api.post('/logs/courses/'+courseUid+'/view');        
      return;       
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      try {      
        await api.post('/logs/courses/'+courseUid+'/view');
        return;     
      } catch (error) {
        console.log('unauthorized')
        return;
      }                      
    }     
  };

  const createLogCourseLessonVideoView = async (courseUid: string, courseLessonUid: string) => { 
    try {
      await api.post('/logs/courses/'+courseUid+'/lessons/'+courseLessonUid+'/video-view');        
      return;       
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      try {      
        await api.post('/logs/courses/'+courseUid+'/lessons/'+courseLessonUid+'/video-view');
        return;     
      } catch (error) {
        console.log('unauthorized')
        return;
      }                      
    }     
  };

  const createLogCourseLessonVideoViewFull = async (courseUid: string, courseLessonUid: string) => { 
    try {
      await api.post('/logs/courses/'+courseUid+'/lessons/'+courseLessonUid+'/video-view-full');        
      return;       
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      try {      
        await api.post('/logs/courses/'+courseUid+'/lessons/'+courseLessonUid+'/video-view-full');
        return;     
      } catch (error) {
        console.log('unauthorized')
        return;
      }                      
    }     
  };

  return (
    <LogContext.Provider value={{ 
      createLogLessonView, 
      createLogLessonVideoView, 
      createLogLessonVideoViewFull, 
      createLogCourseView,
      createLogCourseLessonVideoView,
      createLogCourseLessonVideoViewFull,
    }}>     
      {children}
    </LogContext.Provider>
  );
};

export const useLogHandler = () => {
  const context = useContext(LogContext);
  return context;
};
