import axios from "axios";

interface IUser {
  uid: string,
}
interface IResponseData {
  user: IUser,
  token: string,
  refreshToken: string,  
}

const BASE_URL = 'https://api.escoladenumerologia.com.br';
//const BASE_URL = 'https://escola-de-numerologia-backend-dev-k4ug74lpbq-uc.a.run.app';

export const signInEmail = async (email: string, password: string) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: BASE_URL+'/auth/login',
      data: { 
        email,
        password,
      },
    });
    if (resp.status === 200 || resp.status === 201) {
      const response: IResponseData = {
        user: resp.data.user,
        token: resp.data.token,
        refreshToken: resp.data.refreshToken,
      }
      return response;
    }    
    return; 
  } catch (error: any) {
    throw new Error(error.response.data.error);   
  }
};

export const signInGoogle = async (credential: string, avatar: string) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: BASE_URL+'/auth/login-google',
      data: { 
        access_token: credential,
        avatar,
      },
    });
    if (resp.status === 200 || resp.status === 201) {
      const response: IResponseData = {
        user: resp.data.user,
        token: resp.data.token,
        refreshToken: resp.data.refreshToken,
      }
      return response;
    }    
    return; 
  } catch (error: any) {
    throw new Error(error.response.data.error);   
  }
};

export const signUp = async (name: string, email: string, password: string) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: BASE_URL+'/auth/signUp',
      data: { 
        name,
        email,
        password,
      },
    });
    if (resp.status === 200 || resp.status === 201) {
      const response: IResponseData = {
        user: resp.data.user,
        token: resp.data.token,
        refreshToken: resp.data.refreshToken,
      }
      return response;
    }    
    return; 
  } catch (error: any) {
    throw new Error(error.response.data.error);   
  }
};

export const resetPassword = async (email: string) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: BASE_URL+'/auth/send-reset-email',
      data: {        
        email,        
      },
    });
    if (resp.status === 200 || resp.status === 201) {
      const response: any = {
        message: resp.data.message,
      }
      return response;
    }    
    return; 
  } catch (error: any) {
    throw new Error(error.response.data.error);   
  }
};

export const resendConfirmationEmail = async (email: string, password: string) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: BASE_URL+'/auth/resend-confirmation-email',
      data: {        
        email,
        password,       
      },
    });
    if (resp.status === 200 || resp.status === 201) {
      const response: any = {
        message: resp.data.message,       
      }
      return response;
    }    
    return; 
  } catch (error: any) {
    throw new Error(error.response.data.error);   
  }
};

export const refreshToken = async (refreshToken: string) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: BASE_URL+'/auth/refresh-token',     
      data: { 
        refreshToken
      },
    });
    if (resp.status === 200 || resp.status === 201) {
      const response: IResponseData = {
        user: resp.data.user,
        token: resp.data.token,
        refreshToken: resp.data.refreshToken,
      }
      return response;
    }    
    return;
  } catch (error) {    
    return; 
  }
};
