import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";
import { useAlert, positions } from 'react-alert'
import { Link } from 'react-router-dom';

import { TextInput } from '../../../components/Form';

import { useErrorHandler } from '../../../contexts/error';

import { resetPassword } from '../../../services/auth';

import './styles.css';

interface FormData {
  email: string,  
}

const PasswordRecovery = () => {
  const formRef = useRef<FormHandles>(null);  
  const [loader, setLoader] = useState(false);
  const { handleError } = useErrorHandler();
  const alert = useAlert() 

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({                
        email: Yup.string()
          .email('Digite um email válido')
          .required('Confirme seu email - obrigatório'),             
      });
      await schema.validate(data, {
        abortEarly: false,
      });      
      try {       
        const response = await resetPassword(data.email);
        console.log(response);
        if (response.message === 'success') {
          alert.show('Você receberá um email para cadastrar nova senha!', 
            { type: 'success', 
              timeout: 10000,
              position: positions.MIDDLE,
            });
        }
      } catch (error: any) {       
        handleError(error.message);
      }      
      formRef.current?.setErrors({});
      reset();
      setLoader(false);      
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);
        });
        formRef.current?.setErrors(validationErrors);              
      }
      setLoader(false);      
    }   
  };

  return (
    <div className="password-recovery">
      <div className="password-recovery-content">
        {
          loader &&
          <div id="loader">
            <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={100} />
          </div> 
        } 
        <div className="password-recovery-main">
          <h2>RECUPERAÇÃO DE SENHA</h2>               
          <div className="form-box">
            <Form ref={formRef} onSubmit={handleSubmit} placeholder=''>
              <p>Confirme seu Email</p>
              <TextInput className='recovery-input' name="email" placeholder="Seu email" type='email' />              
              <div className="button-box">
                <Link to='/'>
                  <strong>Voltar</strong>
                </Link>               
                <button type="submit">Recuperar</button>
              </div>
            </Form>
          </div> 
        </div> 
      </div>
    </div>
  );
}

export default PasswordRecovery;
