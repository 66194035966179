import React from 'react';
import Box from '@mui/system/Box';

import {
  mainStyle,
  contentStyle,
  h2Style,
  olStyle,
  liStyle,
  pStyle,
  strongStyle,
  buttonStyle,
  linkStyle,
} from './styles';

const GRUPO_URL = 'https://chat.whatsapp.com/EDhm7hkGGLK6WtUFCj5fND';

const Community: React.FC = () => { 

  return (
    <Box sx={mainStyle}>
      <Box sx={contentStyle}>
        <Box component='h2' sx={h2Style}>REGRAS DO GRUPO:</Box>
        <Box component='ol' sx={olStyle}>
          <Box component='li' sx={liStyle}>Respeito Mútuo: Todos os membros devem tratar uns aos outros com respeito, 
            cortesia e consideração. Opiniões divergentes são bem-vindas, desde que expressas de maneira respeitosa e 
            construtiva.</Box>
          <Box component='li' sx={liStyle}>Foco no Tema: O objetivo principal do grupo é discutir tópicos relacionados 
            à numerologia e espiritualidade. Mantenha as conversas alinhadas com esse propósito e evite desvios para 
            assuntos não relacionados.</Box>
          <Box component='li' sx={liStyle}>Compartilhamento de Conhecimento: Encorajamos os membros a compartilhar seus 
            conhecimentos, experiências e recursos relacionados à numerologia e espiritualidade para enriquecer o 
            aprendizado coletivo.</Box>
          <Box component='li' sx={liStyle}>Confidencialidade: Respeite a privacidade dos outros participantes. Não 
            compartilhe informações pessoais ou confidenciais de outros membros sem permissão prévia.</Box>
          <Box component='li' sx={liStyle}>Resolução de Conflitos: Em caso de conflitos ou mal-entendidos, busque 
            resolver as questões de forma amigável e respeitosa, priorizando o diálogo e a compreensão mútua.</Box>
          <Box component='li' sx={liStyle}>Participação Ativa: Encorajamos todos os membros a participarem ativamente 
            das discussões e atividades propostas no grupo. Sua contribuição é valiosa para o enriquecimento do 
            aprendizado de todos.</Box>
          <Box component='li' sx={liStyle}>Feedback Construtivo: Ao fornecer feedback sobre o trabalho de outros 
            membros, seja gentil, construtivo e específico. O objetivo é ajudar uns aos outros a crescer e aprimorar 
            suas habilidades.</Box>
          <Box component='li' sx={liStyle}>Evitar Spam e Autopromoção: Evite enviar mensagens irrelevantes, spam ou 
            autopromoção no grupo. Caso queira compartilhar conteúdo próprio ou promover eventos relacionados à numerologia, 
            entre em contato com os administradores do grupo para obter permissão.</Box>
          <Box component='li' sx={liStyle}>Respeito ao Horário: Evite enviar mensagens fora do horário adequado, 
            respeitando o sono e o tempo pessoal dos outros membros.</Box>
          <Box component='li' sx={liStyle}>Avaliação Constante: Periodicamente, os administradores do grupo irão avaliar 
            a conformidade com as regras e o ambiente geral do grupo, tomando medidas necessárias para garantir um ambiente 
            saudável e produtivo para todos os participantes.</Box>
        </Box>
        <Box component='p' sx={pStyle}>Ao seguir estas regras, esperamos criar um ambiente acolhedor, colaborativo e enriquecedor para 
          todos os participantes do grupo de estudos de numerologia e espiritualidade. Juntos, vamos aprender, crescer e nos 
          apoiar nesta jornada de descoberta e autoconhecimento.</Box>
        <Box component='strong' sx={strongStyle}>O NÃO CUMPRIMENTO DAS REGRAS RESULTARÁ NA EXCLUSÃO DA PARTICIPAÇÃO DO GRUPO.</Box>
        <Box component='p' sx={pStyle}>Agradecemos pela compreensão!</Box>

        <Box component='a' sx={linkStyle} href={GRUPO_URL} target='_blank' rel='noreferrer noopener'>
          <Box component='button' sx={buttonStyle}>ACESSAR</Box>
        </Box>        
      </Box>
    </Box>
  );
}

export default Community;
