import { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdClear } from "react-icons/md";
import { IconContext } from "react-icons";

import ICourseLesson from '../../../../data/interfaces/ICourseLesson';

import {
  mainStyle,
  titleBoxStyle,
  h1Style,
  contentStyle,
  imgStyle,
  buttonBoxStyle,
  buttonStyle,
  checkStyle,
  strongStyle,
  modalStyle,
  modalCloseIcon,
  modalStrongStyle,
} from './styles';

interface Props { 
  courseLesson: ICourseLesson,
  courseUid: string,
}
type props = Props

const CourseLesson = ({ courseLesson, courseUid }: props) => {
  const [ open, setOpen ] = useState(false);

  const handleOpen = () => { 
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  }; 

  return (
    <Box sx={mainStyle}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="support-material"
        aria-describedby="support-material"
        disableScrollLock={true}      
      > 
        <Box sx={modalStyle}>
          <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
          <Box component='strong' sx={modalStrongStyle}>Será marcado como concluído quando o video for assistido até o final!</Box>          
        </Box>
      </Modal> 
      <Link to={'/cursos/'+courseUid+'/aula/'+courseLesson.uid} style={{ textDecoration: 'none' }}>  
        <Box sx={contentStyle}>               
          <Box component='img' sx={imgStyle} src={courseLesson.thumbnail} alt={courseLesson.title}/>                   
        </Box>
      </Link> 
      <Box sx={titleBoxStyle}>
        <Box component='h1' sx={h1Style}>{courseLesson.title}</Box>
        <Box sx={buttonBoxStyle}>
          <Link to={'/cursos/'+courseUid+'/aula/'+courseLesson.uid} style={{ textDecoration: 'none' }}> 
            <Box component='button' sx={buttonStyle}>ASSISTIR</Box>            
          </Link>          
        </Box>
        <IconContext.Provider value={{ color: 'var(--color-text-gray)', size: '1.5em',className: "global-class-name" }}>
          {
            courseLesson.isFinishedByUser ?
              <Box sx={{ ... checkStyle, cursor: 'default'}}>
                <Box sx={strongStyle}>Concluído: </Box>
                <MdCheckBox/>
              </Box>
            :
              <Box sx={checkStyle} onClick={handleOpen}>
                <Box sx={strongStyle}>Concluído: </Box>
                <MdCheckBoxOutlineBlank/>
              </Box>
          }          
        </IconContext.Provider>
      </Box>        
    </Box>
  );
}

export default CourseLesson;
