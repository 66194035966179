import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";
import Box from '@mui/system/Box';

import { TextInput, TextArea } from '../../Form';

import { useAppDataHandler } from '../../../contexts/appData';

import { sendEmailQuestions } from "../../../services/api";

import logo from '../../../assets/images/logos/novo_logo.png';

import { 
  mainStyle,
  headerStyle,
  h3Style,
  strongStyle,
  logoStyle,
  formStyle,
  formBoxStyle,
  inputBoxStyle,
  inputStyle,
  textAreaBoxStyle,
  textAreaStyle,
  buttonBoxStyle,
  buttonStyle,
  loaderStyle,
} from './styles';

interface FormData {
  name: string
  email: string
  subject: string
  message: string,
}

const QuestionForm = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  
  const [loader, setLoader] = useState(false); 

  const { loadMeData, user, userProfile } = useAppDataHandler();  

  useEffect(() => {
    async function loadData() {
      if (!user) {       
        await loadMeData();       
      } 
      setLoader(false);    
    }    
    loadData();   
  }, []);

  useEffect(() => {
    handleLoadForm();
  }, [user, userProfile]);

  const handleLoadForm = () => {    
    const initialData = {
      name: user?.name,     
      email: user?.email,      
    };
    formRef.current?.setData(initialData);    
  }; 

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Seu nome é obrigatório'),          
        email: Yup.string()
          .email('Digite um email válido')
          .required('Campo email é obrigatório'),       
        message: Yup.string().min(3,'Mínimo de 3 caracteres').required('Campo dúvidas é obrigatório'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });      
      await sendEmailQuestions(data.name, data.email, 'Dúvidas', data.message);
      formRef.current?.setErrors({});
      reset();
      setLoader(false);     
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);
        });
        formRef.current?.setErrors(validationErrors);              
      }
      setLoader(false);      
    }   
  };

  const handleClickVoltar = async () => {    
    navigate(-1)
  };  

  return (
    <Box sx={mainStyle}>
      {
        loader &&
        <Box sx={loaderStyle}>
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={100} />
        </Box>
      }
      <Box sx={headerStyle}>
        <Box component='img' sx={logoStyle} src={logo} alt='Logo da escola'></Box>
        <Box component='h3' sx={h3Style}>ENVIE SUAS DÚVIDAS!</Box>                      
      </Box>
      <Box sx={formStyle}>
        <Box component='strong' sx={strongStyle}>
          A Ingrid realizará uma aula extra, totalmente gratuita, para esclarecer as questões enviadas 
          pelos alunos. Aproveite esta oportunidade exclusiva para aprofundar seu conhecimento!
        </Box>
        <Form ref={formRef} onSubmit={handleSubmit} placeholder=''>
        
          <Box sx={formBoxStyle}>
            <Box sx={inputBoxStyle}>
              <p>Nome</p>
              <TextInput name="name" placeholder="Seu nome" type='text' styles={inputStyle} />
            </Box>
            <Box sx={inputBoxStyle}>
              <p>Email</p>
              <TextInput name="email" placeholder="Seu email" type='email' styles={inputStyle}/>    
            </Box>
            <Box sx={textAreaBoxStyle}>
              <p>Suas dúvidas</p>
              <TextArea name="message" placeholder="Suas dúvidas" maxLength={600} styles={textAreaStyle} />
            </Box>
          </Box>
          <Box sx={buttonBoxStyle}>
            <Box component='button' sx={buttonStyle} type='button' onClick={handleClickVoltar}>Voltar</Box>
            <Box component='button' sx={buttonStyle} type='submit'>Enviar</Box>
          </Box>
        </Form>
      </Box>       
    </Box>
  );
}

export default QuestionForm;
