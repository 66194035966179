import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import UserPanel from '../../components/PageComponents/UserPanel';
import LessonOrder from '../../components/PageComponents/LessonOrder';
import LessonVideoPlayer from '../../components/PageComponents/LessonVideoPlayer';
import Footer from '../../components/PageComponents/Footer';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import ILesson from '../../data/interfaces/ILesson';

import { 
  mainStyle,
  contentStyle,
  h2Style,
} from './styles';

const LessonPage = () => {
  const [ lesson, setLesson ] = useState<ILesson|null>(null);
  const [ loader, setLoader ] = useState(true);
  
  let { lessonUid } = useParams();
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {     
      const response = await api.get('/lessons/'+lessonUid);
      if (response.data) {
        setLesson(response.data.lesson);
        setLoader(false);
      }      
    } catch (error) {
      console.log('unauthorized')
    }
  };

  useEffect(() => {

    async function loadData() {
      try {
        const response = await api.get('/lessons/'+lessonUid);        
        if (response.data) {          
          setLesson(response.data.lesson);
          setLoader(false);
        }            
      } catch (error: any) {
        if (error.message === 'unauthorized') {
          await handleRefreshToken();
          reloadData();     
        } 
        setLoader(false);              
      }     
    }    
    loadData();    
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      {
        lesson ?
          lesson.isAcquired ?
            <LessonVideoPlayer lesson={lesson}/>
          :
            lesson.value > 0 ?
              <LessonOrder lesson={lesson}/>
            :
              <LessonVideoPlayer lesson={lesson}/>              
        :
        loader ?
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
        :
          <Box sx={contentStyle}>
            <Box component='h2' sx={h2Style}>Mentoria não encontrada!</Box>
          </Box>      
      }
      <Footer />
    </Box>
  );
}

export default LessonPage;
