import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";
import { useAlert, positions } from 'react-alert'
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import { MdClear } from "react-icons/md";

import { TextInput, SelectInput } from '../../Form';

import { useErrorHandler } from '../../../contexts/error';
import { useAppDataHandler } from '../../../contexts/appData';
import { useAuth } from '../../../contexts/auth';

import api from '../../../services/api';

import { 
  mainStyle,
  headerStyle,
  h3Style,
  formStyle,
  formBoxStyle,
  inputBoxStyle,
  profileInput,
  profileSelect,
  profileSelectCountries,
  nameBoxStyle,
  birthdayBoxStyle,
  documentBoxStyle,
  emailBoxStyle,
  phoneBoxStyle,
  whatsappBoxStyle,
  addressBoxStyle,
  numberBoxStyle,
  complementBoxStyle,
  neighborhoodBoxStyle,
  cityBoxStyle,
  stateBoxStyle,
  countryBoxStyle,
  zipcodeBoxStyle,
  buttonBoxStyle,
  buttonStyle,
  modelStyle,
  modalCloseIcon,
  modalPStyle,
  modalStrongStyle,
  modalInfoStyles,
  modalWarningStyle,
  modalButtonStyle,
  confirmationModalStyle,
  confirmationButtonBoxStyle,
} from './styles';

interface FormData {
  name: string,        
  birthday: string,
  document: string,
  email: string,
  phoneNumber: string,
  whatsapp: string,     
  address: string,
  number: string,
  complement: string,
  neighborhood: string,
  city: string,
  state: string,
  zipcode: string,
  country: string, 
};

const UserProfile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);  
  const [ open, setOpen ] = useState(false);
  const [ openConfirmation, setOpenConfirmation ] = useState(false);
  const [ loader, setLoader ] = useState(true);
  const [ modalLoader, setModalLoader ] = useState(false);
  const [ countries, setCountries ] = useState<any>(null);
  const [ country, setCountry ] = useState<any>('');
  const [ uf, setUf ] = useState<any>(null);
  const { handleError } = useErrorHandler();
  const { handleSignOut } = useAuth();
  const alert = useAlert() 
  const navigate = useNavigate();
  
  const { loadMeData, deleteUserAccount, user, userProfile } = useAppDataHandler();

  useEffect(() => {
    async function loadData() {
      let resp = await api.get('/utils/countries');
      if (resp) setCountries(resp.data);
      resp = await api.get('/utils/states');
      if (resp) setUf(resp.data);     
      if (!user) {       
        await loadMeData();       
      }       
      setLoader(false);    
    }    
    loadData();         
  }, []);

  useEffect(() => {
    handleLoadForm();
  }, [user, userProfile]);

  useEffect(() => {   
    handleLoadForm();
    handleSelect();
  }, [countries, uf]);

  const handleLoadForm = () => {    
    const initialData = {
      name: user?.name,        
      birthday: userProfile?.birthday,
      document: userProfile?.document,
      email: user?.email,
      phoneNumber: userProfile?.phoneNumber,
      whatsapp: userProfile?.whatsapp,     
      address: userProfile?.address,
      number: userProfile?.number,
      complement: userProfile?.complement,
      neighborhood: userProfile?.neighborhood,
      city: userProfile?.city,
      state: userProfile?.state,
      zipcode: userProfile?.zipcode,
      country: userProfile?.country,  
    };
    formRef.current?.setData(initialData);        
  }; 

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({                
        name: Yup.string().required('Nome é obrigatório'),        
        birthday: Yup.string().optional(),       
        document: Yup.string().matches(/^[0-9]*$/, 'Apenas números'),//.required('Obrigatório para Brasileiros'),
        phoneNumber: Yup.string().optional(),
        whatsapp: Yup.string().optional(),         
        address: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Endereço é obrigatório'),
        number: Yup.string().required('Obrigatório'),
        complement: Yup.string().optional(),
        neighborhood: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Bairro é obrigatório'),
        city: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Cidade é obrigatório'),
        state: Yup.string().required('Campo é obrigatório'),
        zipcode: Yup.string().min(3, 'Mínimo de 3 caracteres').required('CEP é obrigatório'),
        country: Yup.string().required('País é obrigatório'),                
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      if (!cpfValidator.isValid(data.document) && data.country === 'Brasil') {       
        throw new Error('cpf');        
      }
      try {       
        await api.put('/profiles/my-profile', data);     
        alert.show('Perfil atualizado com sucesso!', 
          { type: 'success', 
            timeout: 5000,
            position: positions.MIDDLE,
          });        
      } catch (error: any) {       
        handleError(error.message);
      }      
      formRef.current?.setErrors({});      
      setLoader(false);
      await loadMeData();      
    } catch (err: any) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {          
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);          
        });
        formRef.current?.setErrors(validationErrors);              
      }      
      if (err.message === 'cpf') {        
        formRef.current?.clearField('document');  
        formRef.current?.setErrors({ 'document': 'O CPF é inválido' });  
      }
      setLoader(false);      
    }   
  };

  const handleOpen = () => { 
    setOpen(true);    
  };

  const handleClose = () => {    
    setOpen(false);    
  };

  const handleOpenConfirmation = () => { 
    setOpenConfirmation(true);    
  };

  const handleCloseConfirmation = () => { 
    setOpenConfirmation(false);    
  };

  const handleDeleteUserAccount = async () => {
    setModalLoader(true);
    await deleteUserAccount();    
    setModalLoader(false);
    handleSignOut();
    navigate('/');    
  }

  const handleSelect = () =>{
    const value = formRef.current?.getFieldValue('country');
    setCountry(value);    
  };

  return (
    <Box sx={mainStyle}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="user-info"
        aria-describedby="user-info"
        disableScrollLock={true}      
      >
         <Box sx={modelStyle}>
            <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
            <Box component='strong' sx={modalStrongStyle}>Visite nossos Termos de Uso e Privacidade</Box>
            <Link to='/termos-de-uso-e-privacidade'>
              <Box component='p' sx={modalPStyle}>Acesse aqui</Box>
            </Link>
            <Box sx={modalInfoStyles}>
              <Box component='strong' sx={modalWarningStyle}>Importante:</Box>
              <Box component='p' sx={modalPStyle}>Ao clicar em Excluir Conta, todos os dados do usuário
                serão permanentemente excluidos da plataforma da Escola de Numerologia, incluindo todo o conteúdo 
                adquirido, mentorias, cursos e demais materiais de apoio. Não será possível recuperar o acesso 
                ao conteúdo prévio após a confirmação da exclusão de conta, sendo necessário novo cadastro e 
                novas aquisições.</Box>
            </Box>
            <Box component='button' sx={modalButtonStyle} onClick={handleOpenConfirmation}>EXCLUIR CONTA</Box>
         </Box>
      </Modal>
      <Modal
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="confirmation"
        aria-describedby="confirmation"
        disableScrollLock={true}      
      >
        <Box sx={confirmationModalStyle}>
          <Box component='strong' sx={modalWarningStyle}>Procedimento Irreversível</Box>
          <Box component='strong' sx={modalStrongStyle}>Deseja continuar?</Box>
          <Box sx={confirmationButtonBoxStyle}>
            {
              modalLoader ?          
                <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />
              :
                <>
                  <Box component='button' sx={modalButtonStyle} onClick={handleDeleteUserAccount}>Sim</Box>
                  <Box component='button' sx={modalButtonStyle} onClick={handleCloseConfirmation}>Não</Box>
                </>
}
          </Box>
        </Box>
      </Modal>
      <Box sx={headerStyle}>
        <Box component='h3' sx={h3Style}>DADOS DO ALUNO(A)</Box>                      
      </Box>          
      <Box sx={formStyle}>
        <Form ref={formRef} onSubmit={handleSubmit} placeholder=''>
        
          <Box sx={formBoxStyle}>
            <Box>
              <Box sx={nameBoxStyle}>
                <p>Nome Completo *</p>
                <TextInput styles={profileInput} name="name" type='text' />
              </Box>
              <Box sx={inputBoxStyle}>
                <Box sx={birthdayBoxStyle}>
                  <p>Data de Nascimento</p>
                  <TextInput styles={profileInput} name="birthday" type='text' />
                </Box>
                <Box sx={documentBoxStyle}>
                  <p>CPF (Apenas números) *</p>
                  <TextInput styles={profileInput} name="document" type='text' /> 
                </Box>
              </Box>
              <Box sx={emailBoxStyle}>                     
                <p>Email (Não pode ser alterado)</p>
                <TextInput styles={profileInput} name="email" type='email' disabled={true}/>
              </Box>
              <Box sx={inputBoxStyle}>
                <Box sx={phoneBoxStyle}>
                  <p>Telefone de Contato</p>
                  <TextInput styles={profileInput} name="phoneNumber" type='text' />
                </Box>
                <Box sx={whatsappBoxStyle}>
                  <p>Whatsapp</p>
                  <TextInput styles={profileInput} name="whatsapp" type='text' /> 
                </Box>
              </Box>
            </Box>                

            <Box>
              <Box sx={inputBoxStyle}>
                <Box sx={addressBoxStyle}>
                  <p>Endereço *</p>
                  <TextInput styles={profileInput} name="address" type='text' />
                </Box>
                <Box sx ={numberBoxStyle}>
                  <p>Número *</p>
                  <TextInput styles={profileInput} name="number" type='text' /> 
                </Box>
              </Box> 
              <Box sx={complementBoxStyle}>
                <p>Complemento</p>
                <TextInput styles={profileInput} name="complement" type='text' />
              </Box>
              <Box sx={neighborhoodBoxStyle}>
                <p>Bairro *</p>
                <TextInput styles={profileInput} name="neighborhood" type='text' />  
              </Box>              
              <Box sx={cityBoxStyle}>
                <p>Cidade *</p>
                <TextInput styles={profileInput} name="city" type='text' />
              </Box>
              <Box sx={inputBoxStyle}>
                {
                  uf ?
                    country === 'Brasil' ?
                      <Box sx={stateBoxStyle}>
                        <p>UF *</p>
                        <SelectInput styles={profileSelect} name='state' options={uf}/>
                      </Box>
                    :
                      <Box sx={stateBoxStyle}>
                        <p>Estado *</p>
                        <TextInput styles={profileInput} name='state' type='text'/>
                      </Box>
                  :
                  <Box sx={stateBoxStyle}>
                    <p>UF *</p>
                    <TextInput styles={profileInput} name='state' type='text'/>
                  </Box>
                }
                <Box sx={zipcodeBoxStyle}>  
                  <p>CEP *</p>
                  <TextInput styles={profileInput} name="zipcode" type='text' />  
                </Box>
              </Box>              
              <Box sx={inputBoxStyle}>
                {
                  countries ?
                  <Box sx={countryBoxStyle} onChange={handleSelect}>
                    <p>País *</p>
                    <SelectInput styles={profileSelectCountries} name="country" options={countries}/>  
                  </Box>
                  :
                  <Box sx={countryBoxStyle}>
                    <p>País *</p>
                    <TextInput styles={profileInput} name="country" type='text'/>  
                  </Box>
                }                
              </Box>
            </Box> 
          </Box>                                                        

          <Box sx={buttonBoxStyle}>
            <Box component='button' sx={buttonStyle} type='button' onClick={() => {handleOpen()}}>Gerenciar Conta</Box>
            {
              loader &&
                <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />
            }  
            <Box component='button' sx={buttonStyle} type='submit'>Salvar</Box>
          </Box>
        </Form>
      </Box>         
    </Box>
  );
}

export default UserProfile;
