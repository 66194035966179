import { useState, useEffect } from 'react';
import Box from '@mui/system/Box';
import ReactPlayer from 'react-player'

import IBonusLesson from '../../../data/interfaces/IBonusLesson';

//import { useLogHandler } from '../../../contexts/logs';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  videoBoxStyle,
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,
  linkStyle, 
  reactPlayer, 
} from './styles';

interface Props { 
  bonusLesson: IBonusLesson;
}
type props = Props

const BonusLesson = ({ bonusLesson }: props) => {    
  //const [ open, setOpen ] = useState(false);

  //const { createLogLessonVideoView } = useLogHandler();

  /*useEffect(() => {
    createLogLessonVideoView(lesson.uid);
  }, []);*/

  /*const handleOpen = () => { 
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  };*/ 

  return (
    <Box sx={mainStyle}>          
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{bonusLesson.title}</Box> 
        </Box>
        <Box sx={videoBoxStyle}>
          <ReactPlayer
            style={reactPlayer}
            url={bonusLesson.video}
            width='100%'
            height='100%'
            controls
            pip
            light={<img style={imgStyle} src={bonusLesson.thumbnail} alt='Thumbnail' />}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{bonusLesson.workload}</Box> 
        </Box>
        <Box component='p' sx={descriptionStyle}>{bonusLesson.description}</Box>         
      </Box>
    </Box>
  );
};

export default BonusLesson;
