import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import { MdClear } from "react-icons/md";
import Loader from "react-js-loader";
import { useAlert, positions } from 'react-alert'

import orderStatus from '../../../data/enums/orderStatus';

import ILesson from '../../../data/interfaces/ILesson';

import api from '../../../services/api';

import DateUtils from '../../../utils/dateUtils';

import { useLogHandler } from '../../../contexts/logs';
import { useAppDataHandler } from '../../../contexts/appData';

import pagbank from '../../../assets/images/pagbank.png';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  thumbnailBoxStyle,
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,
  valueStyle,
  buttonPagBankStyle,
  linkStyle,
  modelStyle,
  modalCloseIcon,
  modalMessageStyle,
  modalCancelStyle,
  notReleasedStyle,
  releasedInStyle,
} from './styles';

interface Props { 
  lesson: ILesson;  
}
type props = Props

const LessonOrder = ({ lesson }: props) => {
  const [ open, setOpen ] = useState(false);
  const [ message, setMessage ] = useState<string>('OLá');
  const [ url, setUrl ] = useState<string>('');  
  const [ loader, setLoader ] = useState<boolean>(false);
  const alert = useAlert();
  const navigate = useNavigate();

  const { createLogLessonView } = useLogHandler();
  const { userProfile } = useAppDataHandler();

  const dateUtils = DateUtils();
  
  useEffect(() => {
    createLogLessonView(lesson.uid);      
    if (lesson.orderStatus === orderStatus.CREATED) {
      setMessage('Pedido em processamento! Aguarde finalização ou tente novamente em 10 minutos...');      
      handleOpen();      
    }
    if (lesson.orderStatus === orderStatus.WAITING) {
      setMessage('Aguardando Pagamento do Boleto...');
      handleOpen();
    }        
  },[]);

  const handleClickBuyNow = async () => {
    try {
      if (userProfile?.status === 'pending') {
        navigate('/cadastro');
      } else {       
        setLoader(true);
        const data = {
          lessonUid: lesson.uid,       
        }
        const response = await api.post('/orders/lessons', data);        
        if (response.data) {        
          setUrl(response.data.url);                        
        }
        setLoader(false);       
      }            
    } catch (error: any) {
      setLoader(false);     
      if (lesson.orderStatus === orderStatus.CREATED) {
        setMessage('Pedido em processamento! Aguarde finalização ou tente novamente em 10 minutos...');      
        handleOpen();      
      }
      if (lesson.orderStatus === orderStatus.WAITING) {
        setMessage('Aguardando Pagamento do Boleto...');
        handleOpen();
      }                    
    }     
  };

  const handleClickPagBank = () => {
    setUrl('');
    //setLoader(true)
    lesson.orderStatus = orderStatus.CREATED;    
  };

  const handleClickCancelPayment = async () => {
    await api.delete('/orders/lessons/'+lesson.uid);
    handleClose();
    alert.show('Pedido cancelado com sucesso!', 
      { type: 'success', 
        timeout: 5000,
        position: positions.MIDDLE,
      });    
  }

  const handleOpen = () => { 
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  };  

  return (
    <Box sx={mainStyle}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="order-info"
        aria-describedby="order-info"
        disableScrollLock={true}      
      > 
        <Box sx={modelStyle}>
          <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
          <Box sx={modalMessageStyle}>{message}</Box>
          {
            lesson.orderStatus === orderStatus.WAITING &&
            <Box sx={modalMessageStyle}>
              Caso não tenha pago e deseje cancelar o pedido 
              <Box sx={modalCancelStyle} onClick={handleClickCancelPayment}>clique aqui!</Box>
            </Box>
          }          
        </Box>
      </Modal>         
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{lesson.title}</Box> 
        </Box>
        <Box sx={thumbnailBoxStyle}>
          <Box component='img' sx={imgStyle} src={lesson.thumbnail} alt='thumbnail'/>
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{lesson.workload}</Box> 
        </Box>
        <Box component='p' sx={descriptionStyle}>{lesson.description}</Box>
        {
          !lesson.isReleased ?           
            (lesson.value == 0 && lesson.workload === '') ?
              <Box sx={notReleasedStyle}>EM BREVE</Box>
            :   
              <Box sx={notReleasedStyle}>
                <Box component='strong' sx={releasedInStyle}>DISPONÍVEL EM {dateUtils.handleDate(lesson.releaseDate)}</Box>
                <Box component='strong' sx={{...releasedInStyle, color: 'var(--color-text-value)'}}>VALOR: R$ {lesson.value}</Box>
              </Box>  
          :
            <Box sx={buttonBoxStyle}>
              {
                url !== '' ?
                <>
                  {
                    loader ?
                      <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
                    :
                      <Box component='a' sx={linkStyle} href={url} /*target='_blank' rel='noreferrer noopener'*/>
                        <Box component='button' sx={buttonPagBankStyle} type='button' onClick={handleClickPagBank}>
                          PAGAR COM <img src={pagbank} alt='PAGBANK'/>
                        </Box>
                      </Box>
                  }              
                </>
                :
                <>
                  {
                    <Box component='button' sx={buttonStyle} type='button' onClick={handleClickBuyNow}> 
                      {
                        loader ?
                          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} /> 
                        : 
                        <>
                          <div>COMPRAR AGORA</div>
                          <Box component='strong' sx={valueStyle}>R$ {lesson.value}</Box>
                        </>                    
                      }                  
                    </Box>
                  } 
                </>
              }                    
            </Box>
        }
      </Box>
    </Box>
  );
};

export default LessonOrder;
