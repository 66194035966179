import React, { useEffect, useState } from 'react';
import  { Link, useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import { MdDensitySmall, MdClear, MdEdit } from "react-icons/md";
import { IconContext } from "react-icons";
import { useFilePicker } from 'use-file-picker';
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator, 
} from 'use-file-picker/validators';
import axios from 'axios';

import AppBarMenu from '../AppBarMenu';

import api from '../../../services/api';

import { useAuth } from '../../../contexts/auth';
import { useErrorHandler } from '../../../contexts/error';
import { useAppDataHandler } from '../../../contexts/appData';

import logo from '../../../assets/images/logos/novo_logo.png';
import avatar from '../../../assets/images/avatar.png';

import { 
  mainStyle,
  headerStyle,
  logoStyle,
  userMenuStyle,
  imgStyle,
  avatarStyle,  
  h1Style,  
  separatorStyle,  
  linkStyle,
  modalAvatarBox,
  modalAvatarStyle,
  modalAvatarUpdateIcon,
  modalButtonBoxStyle,
  modalButtonStyle,
  modalCloseIcon,
  modalH1Style,
  modalStrongStyle,
  modalStyle,
} from './styles';

const SUPORTE_URL = 'https://escoladenumerologia.com.br/fale-conosco';
const ESCOLA_URL = 'https://escoladenumerologia.com.br';

const UserPanel: React.FC = () => {  
  const [ open, setOpen ] = useState(false);
  const [ avatarImg, setAvatarImg ] = useState<any>(avatar);  
  const [ modalLoader, setModalLoader ] = useState(false);

  const { handleSignOut } = useAuth();
  const { handleError } = useErrorHandler();
  const { loader, user, userProfile, loadMeData, resetUser } = useAppDataHandler();
  const navigate = useNavigate();
  const { openFilePicker, filesContent, errors } = useFilePicker({
    readAs: 'ArrayBuffer',
    accept: 'image/*',
    multiple: true,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(['jpg', 'png', 'jpeg']),
      new FileSizeValidator({ maxFileSize: 10 * 1024 * 1024 /* 10 MB */ }),     
    ],
  });  

  useEffect(() => {       
    if (user && user.avatar !== '') setAvatarImg(user.avatar);   
  }, [user]);  

  useEffect(() => {   
    async function handledAvatar() {
      try {
        if (filesContent && filesContent.length > 0) {
          setModalLoader(true);          
          let response: any = await api.put('/users/my-avatar');      
          response = await axios({
            method: 'PUT',
            url: response.data.url,
            data: filesContent[0].content,
            headers: {
              'Content-Type': 'image/jpeg',
            }                           
          });
          if (response.status === 200 || response.status === 201) { 
            const resp = await loadMeData();
            setModalLoader(resp);            
          }
        }        
      } catch (error) {
        console.log('error:', error);
      }
    };   
    handledAvatar();     
  },[filesContent]);

  useEffect(() => {      
    if (errors && errors.length > 0) handleError(errors[0].name);
  },[errors]);  

  const handleLogoutClick = async () => {    
    resetUser();
    handleSignOut();
    navigate('/');      
  };

  const handleOpen = () => { 
    setOpen(true);    
  };

  const handleClose = () => {    
    setOpen(false);    
  }; 
    
  return (
    <Box sx={mainStyle}>      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="user-info"
        aria-describedby="user-info"
        disableScrollLock={true}      
      > 
        <Box sx={modalStyle}>
          <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
          <Box sx={modalAvatarUpdateIcon} onClick={() => {openFilePicker()}}><MdEdit/></Box>             
          <Box sx={modalAvatarBox}>
            {
              modalLoader ?          
                <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />
              :                
                <Avatar alt={user.name} src={avatarImg} sx={modalAvatarStyle} onClick={() => {openFilePicker()}}/>
            }
            <Box component='h1' sx={modalH1Style}>Olá, {user.name && user.name.split(' ')[0]}</Box>
            <Box component='strong' sx={modalStrongStyle}>{user && user.email}</Box>
          </Box>
          <Box sx={modalButtonBoxStyle}>
            <Link to='/perfil' style={{ textDecoration: 'none' }}>
              <Box component='button' sx={modalButtonStyle}>MEU PERFIL</Box>
            </Link>      
            <Link to='/pedidos' style={{ textDecoration: 'none' }}>
              <Box component='button' sx={modalButtonStyle}>MEUS PEDIDOS</Box>
            </Link>     
            <Box component='a' sx={linkStyle} href={SUPORTE_URL} target='_blank' rel='noreferrer noopener'>
              <Box component='button' sx={modalButtonStyle}>SUPORTE</Box>      
            </Box>          
            <Box component='button' sx={modalButtonStyle} onClick={() => handleLogoutClick()}>SAIR</Box>
          </Box>          
        </Box>
      </Modal>     
      
      <Box sx={headerStyle}>
        <Box sx={logoStyle}>
          <Box component='a' sx={linkStyle} href={ESCOLA_URL} target='_blank' rel='noreferrer noopener'>
            <Box component='img' sx={imgStyle} src={logo} alt='logo'/>            
          </Box>          
        </Box>
        {
          loader ?          
            <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />
          :
            <Box component='h1' sx={h1Style}>ÁREA DO ALUNO</Box>
        }      
        <IconContext.Provider value={{ color: 'var(--color-background-icon)', size: '2em',className: "global-class-name" }}>
          <Tooltip 
            title="Opções do Usuário"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
          >            
            <Box sx={userMenuStyle} onClick={handleOpen}>
              <MdDensitySmall/>
              <Avatar alt={user.name} src={avatarImg} sx={avatarStyle}/>                          
            </Box>
          </Tooltip>
        </IconContext.Provider>        
      </Box>        
        
      {/*<Box sx={menuStyle}>
        <Box sx={menuBlockLeft}>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Box component='button' sx={buttonStyle}>INÍCIO</Box>
          </Link>
          <Link to='/mentorias' style={{ textDecoration: 'none' }}>    
            <Box component='button' sx={buttonStyle}>MENTORIAS</Box>
          </Link>       
        </Box>
        <Box sx={menuBlockRight}>             
          <Link to='/cursos' style={{ textDecoration: 'none' }}>
            <Box component='button' sx={buttonStyle}>CURSOS</Box>
          </Link>
          <Link to='/comunidade' style={{ textDecoration: 'none' }}>
            <Box component='button' sx={buttonStyle} >COMUNIDADE</Box>
          </Link>
        </Box>         
      </Box>*/}      
        
      <Box sx={separatorStyle} />
      <AppBarMenu />      
    </Box>  
  );
}

export default UserPanel;
