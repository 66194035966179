import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import UserPanel from '../../components/PageComponents/UserPanel';
import CoursesList from '../../components/PageComponents/CoursesList';
import Footer from '../../components/PageComponents/Footer';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import { mainStyle } from './styles';

const CoursesListPage: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Cursos Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/cursos', 'Cursos Page');   
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />
      <CoursesList />
      <Footer />
    </Box>
  );
}

export default CoursesListPage;
