import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { MdClear } from "react-icons/md";

import imgEscola from '../../../assets/images/banner_escola.png';
import imgCursos from '../../../assets/images/banner_livros.png';
import imgFree from '../../../assets/images/banner_free.png';
import imgLabelFree from '../../../assets/images/label_free.png';

import {
  mainStyle,
  contentStyle,
  cardStyle,
  titleBoxStyle,
  h2Style,
  freeBannerStyle,
  freeLabelStyle,
  imageBoxStyle,
  imgStyle,
  buttonBoxStyle,
  buttonStyle,
  modalStyle,
  modalCloseIcon,
  attachmentsStyle,
  attachmentUrlStyle,
} from './styles';

const URL_MANUAL = 'https://storage.googleapis.com/ingrid-dalila-engel-arquivos-publicos/manual.pdf';
const URL_FOLHA_CALCULO = 'https://folha.truenumbers.com.br';
const URL_MODELO_INTERPRETACAO = 'https://famosos.truenumbers.com.br';
const URL_9_PORTAIS = 'https://aluno.escoladenumerologia.com.br/mentorias/bonus/582a1003-6816-4d44-b36a-f1477b95e2b0';

const Main: React.FC = () => {
  const [ open, setOpen ] = useState(false);

  const handleOpen = () => { 
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  }; 

  return (
    <Box sx={mainStyle}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="free-material"
        aria-describedby="free-material"
        disableScrollLock={true}      
      > 
        <Box sx={modalStyle}>
          <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
          <Box component='strong'>Material Gratuito:</Box>
          <Box sx={attachmentsStyle}>
            <Box component='a' sx={attachmentUrlStyle} href={URL_MANUAL} target='_blank' rel='noreferrer noopener'>MANUAL DE NUMEROLOGIA</Box>
            <Box component='a' sx={attachmentUrlStyle} href={URL_FOLHA_CALCULO} target='_blank' rel='noreferrer noopener'>PROGRAMA DE CÁLCULO</Box>
            <Box component='a' sx={attachmentUrlStyle} href={URL_MODELO_INTERPRETACAO} target='_blank' rel='noreferrer noopener'>MODELO DE INTERPRETAÇÃO</Box>
            <Box component='a' sx={attachmentUrlStyle} href={URL_9_PORTAIS}>OS 9 PORTAIS</Box>
          </Box>
        </Box>
      </Modal> 

      <Box component='img' sx={freeLabelStyle} src={imgLabelFree} alt='conteudo_gratuito' onClick={handleOpen}/>     
      <Tooltip 
        title="Acessar Material Gratuito"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
      > 
        <Box component='img' sx={freeBannerStyle} src={imgFree} alt='conteudo_gratuito' onClick={handleOpen}/>
      </Tooltip>

      <Box sx={contentStyle}>
        <Tooltip 
          title="Acessar Mentorias"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
        > 
          <Box sx={cardStyle}>
            <Box sx={titleBoxStyle}>
              <Box component='h2' sx={h2Style}>MENTORIAS</Box>
            </Box>
            <Link to='/mentorias' style={{ textDecoration: 'none' }}>
              <Box sx={imageBoxStyle}>
                <Box component='img' src={imgEscola} alt='mentorias' sx={imgStyle} />
              </Box>
            </Link>
            <Box sx={buttonBoxStyle}>
              <Link to='/mentorias' style={{ textDecoration: 'none' }}>
                <Box component='button' sx={buttonStyle}>ACESSAR</Box>
              </Link>
            </Box>
          </Box>
        </Tooltip>

        <Tooltip 
          title="Acessar Cursos para Iniciantes"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
        > 
          <Box sx={cardStyle}>
            <Box sx={titleBoxStyle}>
              <Box component='h2' sx={h2Style}>CURSOS</Box>
            </Box>
            <Link to='/cursos' style={{ textDecoration: 'none' }}>
              <Box sx={imageBoxStyle}>
                <Box component='img' src={imgCursos} alt='cursos' sx={imgStyle} />
              </Box>
            </Link>
            <Box sx={buttonBoxStyle}>
              <Link to='/cursos' style={{ textDecoration: 'none' }}>
                <Box component='button' sx={buttonStyle}>ACESSAR</Box>
              </Link>
            </Box>
          </Box>
        </Tooltip> 
      </Box>
    </Box>
  );
}

export default Main;
