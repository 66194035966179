import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';

import ILesson from '../../../../data/interfaces/ILesson';

import DateUtils from '../../../../utils/dateUtils';

import {
  mainStyle,
  titleBoxStyle,
  h1Style,
  contentStyle,
  imgStyle,
  buttonBoxStyle,
  buttonStyle,
  buttonOrderStyle,
} from './styles';

interface Props { 
  lesson: ILesson
}
type props = Props

const Lesson = ({ lesson }: props) => {
  const dateUtils = DateUtils();

  return (
    <Box sx={mainStyle}>
      <Link to={'/mentorias/'+lesson.uid} style={{ textDecoration: 'none' }}>        
        {
          !lesson.isReleased ?
            <Box sx={titleBoxStyle}>
              {/*<Box component='h1' sx={h1Style}>DISPONÍVEL EM {dateUtils.handleDate(lesson.releaseDate)}</Box>*/}
              <Box component='h1' sx={h1Style}>{lesson.title}</Box>
            </Box>
          :
            <Box sx={titleBoxStyle}>
              <Box component='h1' sx={h1Style}>{lesson.title}</Box> 
            </Box>
        }
        <Box sx={contentStyle}>               
          <Box component='img' sx={imgStyle} src={lesson.thumbnail} alt={lesson.title}/>                   
        </Box>
      </Link>
      <Box sx={buttonBoxStyle}>
        <Link to={'/mentorias/'+lesson.uid} style={{ textDecoration: 'none' }}>  
        {
          lesson.isAcquired ? 
            <Box component='button' sx={buttonStyle}>ACESSAR</Box>
          :
            lesson.value > 0 ?
              <Box component='button' sx={buttonOrderStyle}>SAIBA MAIS</Box>
            :
              <Box component='button' sx={buttonStyle}>ACESSAR</Box>  
        }
        </Link>
      </Box>     
    </Box>
  );
}

export default Lesson;
