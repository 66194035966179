import { MdOpenInBrowser } from "react-icons/md";

import IOrder from '../../../../data/interfaces/IOrder';

import OrderUtils from '../../../../utils/orderUtils';

import './styles.css';

interface Props { 
  order: IOrder
}
type props = Props

const Order = ({ order }: props) => {
  const orderUtils = OrderUtils();  

  return (
    <div className="order">      
      <div className="order-content">        
        <p>{orderUtils.handleDateHour(order.updatedAt)}</p>
        <p>{orderUtils.handleStatus(order.status)}</p>
        <p>{orderUtils.handlePaymentMethod(order.paymentMethod)}</p>
        <MdOpenInBrowser />
      </div>
    </div>
  );
}

export default Order;
