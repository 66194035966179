const orderStatus = {
  AUTHORIZED: 'AUTHORIZED',
  PAID: 'PAID',
  IN_ANALYSIS: 'IN_ANALYSIS',
  DECLINED: 'DECLINED',
  CANCELED: 'CANCELED',
  WAITING: 'WAITING',
  CREATED: 'CREATED',
  COURTESY: 'COURTESY',
  NONE: 'NONE',
}

export default orderStatus;
