import React from 'react';
import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Footer from '../../components/PageComponents/Footer';
import QuestionForm from '../../components/PageComponents/QuestionForm';

import './styles.css';

const QuestionFormPage: React.FC = () => {
  
  const gaEventTracker = useAnalyticsEventTracker('Formulario-de-dúvidas Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/duvidas', 'Formulario-de-dúvidas Page');
  }, []);
  
  return (
    <div id="question-form-page" className="container">     
      <QuestionForm />
      <Footer />          
    </div>
  );
}

export default QuestionFormPage;
