import React from 'react';
import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import SignIn from '../../components/PageComponents/SignIn';
import Footer from '../../components/PageComponents/Footer';

import './styles.css';

const SignInPage: React.FC = () => {
  
  const gaEventTracker = useAnalyticsEventTracker('Login Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/', 'Login Page');
  }, []);
  
  return (
    <div id="signin-page" className="container">     
      <SignIn />
      <Footer />          
    </div>
  );
}

export default SignInPage;
