import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '145rem',
    sm: '115rem', 
    lg: '90rem', 
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center', 
};

export const borderBoxStyle: SxProps = {
  width: {
    xs: '93%',
    sm: '95%',
    lg: '97%',
  },
  height: {
    xs: '140rem',
    sm: '110rem',
    lg: '86rem',
  },
  borderStyle: 'solid',
  borderColor: 'var(--color-background)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const headerStyle: SxProps = {
  width: '100%',
  height: '10rem',  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',    
};

export const h3Style: SxProps = {
  fontSize: '3rem',
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textShadow: '2px 2px var(--color-text-dark-gray)',       
};

export const formStyle: SxProps = {
  width: {
    xs: '35rem',
    sm: '44rem',
    lg: '95rem'
  },
  height: { 
    xs: '115rem',
    sm: '85rem', 
    lg: '60rem', 
  },  
  display: 'flex',
  flexDirection: {
    xs: 'column',    
  },
  justifyContent: 'center',   
  alignItems: 'center',
  borderRadius: '2rem',
  background: 'var(--color-background)',
  marginTop: '2rem',
};

export const formBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '40rem',
    lg: '86rem',
  },
  height: { 
    xs: '98rem',
    sm: '68rem', 
    lg: '43rem', 
  }, 
  display: 'flex',
  flexDirection: {
    xs: 'column',   
    lg: 'row',
  },
  justifyContent: {
    xs: 'center',
    lg: 'space-between',
  },
  alignItems: {
    xs: 'center',
    lg: 'flex-start',
  },  
};

export const profileInput: React.CSSProperties = {
  width: '100%',
  height: '4rem',  
  background: 'var(--color-background-white)',  
  borderStyle: 'solid',
  borderColor: '#bebebe',
  borderRadius: '0.4rem',
  padding: '1rem',
  borderWidth: 'thin',
};

export const profileSelect: React.CSSProperties =  {
  width: '100%',
  height: '4rem',
  borderStyle: 'solid',
  background: 'var(--color-background-white)',
  padding: '1rem',
  borderRadius: '0.4rem',
  borderColor: '#bebebe',  
  textAlign: 'initial',
  fontSize: '1.7rem',
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',
};

export const profileSelectCountries: React.CSSProperties =  {
  width: '100%',
  height: '4rem',
  borderStyle: 'solid',
  background: 'var(--color-background-white)',
  padding: '1rem',
  borderRadius: '0.4rem',
  borderColor: '#bebebe',  
  textAlign: 'initial',
  fontSize: '1.7rem',
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',
};

export const inputBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: {
    xs: 'column',
    sm: 'row',        
  },
  justifyContent: 'space-between',  
  alignItems: {
    xs: 'flex-start',
    sm: 'center',
  }, 
};

export const nameBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '40rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',  
};

export const birthdayBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '17rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const documentBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '21rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const emailBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '40rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const phoneBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '19rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const whatsappBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '19rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const addressBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '30rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const numberBoxStyle: SxProps = {
  width: {
    xs: '10rem',
    sm: '8rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const complementBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '40rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const neighborhoodBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '40rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const cityBoxStyle: SxProps = {
  width: {
    //xs: '32rem',
    //sm: '30rem',
    xs: '32rem',
    sm: '40rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const stateBoxStyle: SxProps = {
  width: {
    //xs: '10rem',
    //sm: '8rem',
    xs: '32rem',
    sm: '24rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const countryBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '24rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const zipcodeBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '14rem',
  },
  height: {
    xs: '7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export const buttonBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    sm: '40rem',
    lg: '86rem',
  },
  height: '4rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '4rem',  
};

export const buttonStyle: SxProps = {
  width: {
    xs: '12rem',
    sm: '14rem',
  },
  height: '3rem',
  backgroundImage: 'linear-gradient(to right, var(--color-button-dark), var(--color-button-light))',
  color: 'var(--color-button-text-light)',
  border: 0,
  borderRadius: '0.4rem',  
  cursor: 'pointer',  
  fontSize: '1.5rem',
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-light-hover)' },  
};

export const logoBoxStyle: SxProps = {  
  width: {
    xs: '6rem',
    lg: '8rem',
  },
  height: {
    xs: '6rem',
    lg: '8rem',
  },
  marginTop: '2rem',
  marginBottom: '2rem',
};

export const aStyle: SxProps = {
  textDecoration: 'none',
};

export const logoImgStyle: SxProps = {
  width: '100%',
  height: '100%',
};