import React, { createContext, useState, useEffect, useContext } from "react";
import { useAlert } from 'react-alert'

import { useErrorHandler } from "./error";

import { signInEmail, signInGoogle, resendConfirmationEmail, refreshToken } from "../services/auth";

import api from '../services/api';

import typeError from '../data/enums/error';

interface AuthContextData {
  signed: boolean,
  uid: string|null,
  loading: boolean,
  handleSignInEmail(email: string, password: string): Promise<void>,
  handleSignInGoogle(credential: string, avatar: string): Promise<void>,
  handleSignOut(): void,
  handleRefreshToken(): Promise<void>,
}
type Props = {
  children?: React.ReactNode
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [ uid, setUid ] = useState<string|null>(null);
  const [ loading, setLoading ] = useState(false);
  const alert = useAlert()
  const { handleError } = useErrorHandler();  

  useEffect(() => {
    const storagedUid = localStorage.getItem('@EN:uid');
    const storagedToken = localStorage.getItem('@EN:token');
    const storagedRefreshToken = localStorage.getItem('@EN:refreshToken');
    
    if (storagedUid && storagedToken && storagedRefreshToken) {
      setUid(storagedUid);      
      setLoading(false);      
      api.defaults.headers['UID'] = storagedUid;
      api.defaults.headers['TOKEN'] = storagedToken;                
    }
  }, []);
  
  const handleSignInEmail = async (email: string, password: string) => {
    try {
      setLoading(true);
      const response = await signInEmail(email, password);
      if (response) {      
        setUid(response.user.uid)      
        localStorage.setItem('@EN:uid', response.user.uid);
        localStorage.setItem('@EN:token', response.token);
        localStorage.setItem('@EN:refreshToken', response.refreshToken);
        api.defaults.headers['UID'] = response.user.uid;
        api.defaults.headers['TOKEN'] = response.token;
        setLoading(false);
      }   
    } catch (error: any) {      
      handleError(error.message);
      if (error.message === typeError.EMAIL_NOT_VERIFIED) {        
        try {
          await resendConfirmationEmail(email, password);
        } catch (error: any) {
         
        }        
      }
    }     
  };

  const handleSignInGoogle = async (credential: string, avatar: string) => {
    try {
      setLoading(true);
      const response = await signInGoogle(credential, avatar);
      if (response) {      
        setUid(response.user.uid)      
        localStorage.setItem('@EN:uid', response.user.uid);
        localStorage.setItem('@EN:token', response.token);
        localStorage.setItem('@EN:refreshToken', response.refreshToken);
        api.defaults.headers['UID'] = response.user.uid;
        api.defaults.headers['TOKEN'] = response.token;
        setLoading(false);
      }   
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const handleSignOut = () => {
    setUid(null);
    localStorage.removeItem('@EN:uid');
    localStorage.removeItem('@EN:token');
    localStorage.removeItem('@EN:refreshToken');   
  };

  const handleRefreshToken = async () => {
    console.log('refresh-token')
    const storagedRefreshToken = localStorage.getItem('@EN:refreshToken');
    if (storagedRefreshToken) {
      const response = await refreshToken(storagedRefreshToken);   
      if (response) {      
        setUid(response.user.uid)      
        localStorage.setItem('@EN:uid', response.user.uid);
        localStorage.setItem('@EN:token', response.token);
        localStorage.setItem('@EN:refreshToken', response.refreshToken);
        api.defaults.headers['UID'] = response.user.uid;
        api.defaults.headers['TOKEN'] = response.token;      
      } else {
        handleSignOut();
        alert.show('Sessão expirada! Entre novamente.', { type: 'info' });
      }  
    } else {
      handleSignOut();
    }    
  };

  return (
    <AuthContext.Provider value={{ signed: Boolean(uid), uid, loading,  handleSignInEmail, handleSignInGoogle, handleSignOut, handleRefreshToken }}>     
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
