import React, { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import './styles.css';

const PrivacyAndUserTermsPage: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Termos-de-uso Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/termos-de-uso-e-privacidade', 'Termos-de-uso Page');
  }, []);

  return (
    <div id="privacy-and-user-terms-page" className="container">
      <div className='terms-content'>
        <p>Bem vindo à plataforma da Escola de Numerologia</p>
        <h1>Termos de Uso e Política de Privacidade</h1>

        <strong>1. Relação do Usuário com a Escola de Numerologia</strong>
        <p>A Escola de Numerologia é uma plataforma dedicada ao ensino e aprofundamento das técnicas da numerologia, 
          onde disponibilizará mentorias online sobre diversos temas da numerologia. 
          O Usuário está a ler os termos de serviço, que regem a relação e servem como um contrato 
          entre o usuário e a Escola de Numerologia e definem os termos e as condições de acesso e utilização, 
          pelo Usuário, da Plataforma e dos sítios Web (tal como o escoladenumerologia.com.br), dos serviços, 
          das aplicações, e dos conteúdos relacionados com a Plataforma e indicados como sendo 
          disponibilizados de acordo com estes Termos. Os serviços são prestados para utilização 
          privada e não comercial. Para efeitos dos presentes Termos, entende-se por «Usuário» 
          o destinatário dos presentes Termos, enquanto Utilizador dos Serviços. Ao acessar ou usar 
          nossos Serviços, você confirma que está livre para firmar contrato vinculante com a Escola de Numerolgia, 
          que aceita estes Termos e se compromete a cumpri-los. O seu acesso aos nossos Serviços, 
          e a sua utilização, também estão sujeitos à nossa Política de Privacidade.</p>

        <strong>2. Descrição dos Serviços</strong>
        <p>Os Serviços e a Plataforma destinam-se apenas a pessoas com competência legal e plenamente aptas a 
          aceitar os presentes Termos. Caso o Usuário seja um menor de idade, para poder acessar e/ou utilizar 
          os Serviços, o mesmo deverá obter autorização de seus pais, tutores ou representante legal, a fim de 
          permitir seu acesso e/ou utilização aos Serviços do site escoladenumerologia.com.br, tornando-se plenamente 
          responsáveis quanto a todos os atos praticados pelo menor, assim como quanto aos conteúdos e serviços 
          acessados e/ou utilizados pelo menor. O Usuário pode utilizar os Serviços e a Plataforma  
          através do sítio Web da Escola de Numerologia.</p>
        <p>Os Serviços permitem ao usuário participar de mentorias online, acessar materiais educativos e interagir com a 
          comunidade da Escola de Numerologia.</p>
        <p><b>Limitações aos Serviços:</b> A Escola de Numerologia empenha todos os seus esforços para prestar Serviços sem 
          interrupções significativas, melhorando-os continuamente. No entanto, é possível que os Serviços fiquem 
          total ou parcialmente indisponíveis durante algum período de tempo por motivos de tempo de paralisação 
          prevista ou imprevista, para manutenção ou em virtude de dificuldades técnicas.</p>

        <strong>3. Aceitação dos Termos</strong>
        <p>Ao proceder ao registo, ao acesso e à utilização dos Serviços, o Usuário aceita que: pode e se encontra a 
          celebrar um contrato vinculativo com a Escola de Numerologia, que compreende os presentes Termos; tem plena capacidade 
          legal para aceitar os presentes Termos; aceita os presentes Termos e que se compromete a cumpri-los.</p>
        <p>Se o Usuário estiver a usar os Serviços em nome de uma empresa ou entidade, a) o Usuário declara e garante 
          que é um representante autorizado da empresa ou entidade em questão, com poderes para vincular a entidade aos 
          presentes Termos, e que os aceita em nome da entidade, e c) a empresa ou entidade do Usuário é financeira e 
          juridicamente responsável pelo acesso aos Serviços, ou pela utilização dos mesmos.</p>
        <p>a)  Responsabilizo-me integralmente e a qualquer tempo pela adequada utilização das informações a que tiver acesso. 
          Estou ciente de que posso vir a ser responsabilizado por danos morais e materiais decorrentes da utilização, 
          reprodução ou divulgação indevida dessas informações. Isento o site escoladenumerologia.com.br, a Administração e os 
          administradores do site, a empresa <b>Truenumbers - Autoconhecimento e Desenvolvimento Pessoal Ltda</b> e seus responsáveis 
          e administradores de qualquer responsabilidade a este  respeito;</p>
        <p>b)  Estou ciente das restrições a que se referem os artigos 4º e 6º da Lei nº 8.159, de 08.01.1991 (Lei de Arquivos); 
          da Lei nº 9.610, de 19.02.1998 (Lei de Direitos Autorais); dos artigos 138 a 145 do Código Penal, que prevê os 
          crimes de calúnia, injúria e difamação; bem como da proibição, decorrente do art. 5º, X, da Constituição da República 
          Federativa do Brasil, de 1988, de difundir as informações obtidas que, embora associadas a interesses particulares, 
          digam respeito à honra e à imagem de terceiros, além do art. 25, §§ 1º e 2º, da Lei Federal nº 12.527, de 18 de 
          novembro de 2011(Lei de Acesso à Informação Pública);</p>
        <p>c)  Estou ciente da obrigatoriedade de, por ocasião da eventual divulgação das referidas informações, mencionar a 
          fonte a que os respectivos originais pertencem;</p>
        <p>d)  Estou ciente de que autorizações relativas a direitos autorais e de imagem, quando pertinentes, devem ser 
          solicitadas aos autores ou  retratados;</p>
        <p>e)  Os casos omissos no presente termo serão resolvidos com observância dos preceitos do Código Civil e de outros 
          dispositivos legais que lhes sejam aplicáveis;</p>
        <p>f)  Declaro, para todos os fins, que li as seguintes condições e estou ciente das minhas responsabilidades, também 
          declaro que o desconhecimento da lei e dos termos aqui tratados não me exime de  responsabilidade.</p>
        <p>Embora a Escola de Numerologia disponha sempre de uma cópia dos seus Termos atuais na Plataforma (incluindo a data da
          última atualização), o Usuário deve imprimir ou guardar uma cópia local dos Termos para sua referência.</p>

        <strong>4. Alterações aos Termos e Serviços</strong>
        <p>A Escola de Numerologia inova e melhora continuamente os Serviços. A Escola de Numerologia pode corrigir também os 
          presentes Termos regularmente, por exemplo, quando atualiza a funcionalidade dos Serviços, ou quando existem 
          alterações regulamentares que afetam os presentes Termos ou Serviços.</p>
        <p>A Escola de Numerologia empregará esforços razoáveis para, com a devida antecedência, através de uma notificação 
          publicada na Plataforma, comunicar todas as alterações substanciais aos presentes Termos ou Serviços que causem 
          desvantagens ao Usuário, ou limitem substancialmente o acesso ou a utilização dos seus Serviços. No entanto, 
          o usuário deve consultar regularmente os Termos para verificar se foram introduzidas essas alterações. 
          A Escola de Numerologia atualizará também a data da «Última atualização» indicada no início dos presentes Termos, 
          a qual reflete a data de entrada em vigor da versão mais recentemente atualizada dos mesmos. Uma vez que a 
          Escola de Numerologia não permite a utilização dos Serviços por pessoas que não aceitam cumprir os seus Termos, 
          o acesso ou a utilização pelo Usuário, de forma contínua, após a data dos novos Termos configura uma aceitação 
          desses novos Termos pelo Usuário. Se o usuário não aceitar os novos Termos, tem de interromper o acesso aos 
          Serviços e encerrar a sua conta.</p>
        <p>Em caso de alterações aos Termos ou Serviços que a Escola de Numerologia precise de efetuar para cumprir as condições 
          de proteção, segurança, jurídicas ou regulamentares, é possível que a Escola de Numerologia não seja capaz de as 
          comunicar ao Usuário com a devida antecedência, mas a mesma informá-lo-á assim que seja praticável.</p>

        <strong>5. Conta do Usuário na Escola de Numerologia</strong>
        <p>Para acessar a alguns dos Serviços da Escola de Numerologia, ou para utilizá-los, o usuário tem de criar uma conta na 
          Escola de Numerologia. Quando criar esta conta, o usuário tem de fornecer informações exatas e atualizadas. 
          É importante que o Usuário mantenha e atualize com prontidão os seus dados e outras informações que forneça à 
          Escola de Numerologia, para manter essas informações atualizadas e completas. Os Termos estão também sempre 
          acessíveis para o Usuário na Plataforma. É importante que o Usuário mantenha a confidencialidade da palavra-chave 
          da sua conta e não a divulgue a qualquer terceiro. Caso o Usuário suspeite ou tenha conhecimento de que um terceiro 
          conhece a sua palavra-chave ou acessou à sua conta, tem de notificar prontamente a Escola de Numerologia através dos 
          canais de atendimento disponíveis na nossa plataforma.</p>

        <strong>6. Pagamentos</strong>
        <p>A Escola de Numerologia utiliza os serviços da empresa PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S/A - CNPJ/MF 08.561.701/0001-01, 
          conhecida como PagBank, para processar todas as transações financeiras realizadas dentro dos sistemas da plataforma.</p>
        <p>Todos os dados fornecidos durante o processo de pagamento trafegarão e serão processados exclusivamente pelos sistemas 
          do PagBank.</p>
        <p>Nenhum dado financeiro e das transações efetuadas pelo usuário passará pela plataforma da Escola de Numerologia, nem será 
          armazenado em nossos servidores em nenhuma forma.</p>
        <p>Adotamos todas as medidas de segurança para proteger as informações dos usuários e recomendamos que os usuários também 
          estejam cientes das práticas de segurança utilizadas pela empresa PagBank.</p>
        <p>Não nos responsabilizamos por quaisquer problemas decorrentes do uso dos sistemas da empresa PagBank para pagamentos em 
          nosso site. Qualquer disputa relacionada a transações financeiras deve ser tratada diretamente com o PagBank.</p>

        <strong>7. Rescisão</strong>
        <p>A Escola de Numerologia reserva-se o direito de temporária ou permanentemente suspender ou rescindir a conta 
          de usuário, ou limitar ou restringir o acesso do Usuário a todos os Serviços ou parte dos mesmos, com ou 
          sem aviso prévio, a qualquer momento e por qualquer motivo:</p>
        <p>➤ se acreditar razoavelmente que o Usuário está a violar, ou tem motivos objetivos para acreditar 
          razoavelmente que o mesmo está prestes a violar os Termos, incluindo todos os acordos, ou todas as 
          leis e os regulamentos aplicáveis;</p>
        <p>➤ se ocorrerem atividades na conta do Usuário que, segundo o critério exclusivo da Escola de Numerologia, 
          causem ou possam causar danos ou prejuízos à Escola de Numerologia ou aos Serviços, ou infringir ou violar 
          quaisquer direitos de um terceiro;</p>
        <p>➤ em resposta a pedidos por aplicação da lei, ou de outras agências governamentais nos termos de um 
          processo judicial válido;</p>
        <p>➤ devido a anomalias ou problemas técnicos ou de segurança inesperados;</p>
        <p>Sob reserva de quaisquer direitos previstos pela lei que o Usuário possa ter, se a sua conta for 
          temporária ou permanentemente suspensa ou rescindida, o acesso ao nome de usuário, à palavra-chave 
          e a quaisquer informações ou conteúdos conexos, associados à mesma pode ser suspenso ou rescindido. 
          O Usuário deve efetuar cópias de segurança de todos os seus conteúdos importantes, uma vez que a 
          Escola de Numerologia não garante a disponibilidade permanente dos mesmos.</p>

        <strong>8. Utilização dos Serviços da Escola de Numerologia pelo Usuário</strong>
        <p>O acesso aos Serviços e a sua utilização pelo Usuário estão sujeitos aos presentes Termos e a todas as 
          leis e regulamentos aplicáveis. O Utilizador não pode:</p>
        <p>➤ acessar aos Serviços, ou utilizá-los, se não for civilmente capaz de aceitar os presentes Termos;</p>
        <p>➤ distribuir, licenciar, transferir, ou vender, total ou parcialmente, qualquer um dos Serviços, 
          ou qualquer obra derivada dos mesmos;</p>
        <p>➤ comercializar ou alugar os Serviços contra o pagamento de uma tarifa ou taxa, ou utilizar a 
          Plataforma para fazer publicidade ou qualquer angariação para fins comerciais, salvo se qualquer uma 
          dessas atividades for expressamente autorizada pela Escola de Numerologia com antecedência;</p>
        <p>➤ utilizar os Serviços, sem o expresso consentimento por escrito da Escola de Numerologia, para qualquer 
          fim comercial ou não autorizado, incluindo para a comunicação ou promoção de qualquer angariação ou anúncio 
          comercial ou para fins de spamming;</p>
        <p>➤ incorporar a Plataforma, ou qualquer parte da mesma, em qualquer outro programa ou produto e, se tal ocorrer, 
          a Escola de Numerologia reserva-se o direito de recusar o serviço, cancelar contas, ou limitar o acesso aos 
          Serviços segundo o critério exclusivo da Escola de Numerologia;</p>
        <p>➤ utilizar qualquer sistema automatizado ou software, desenvolvidos por terceiros ou outras partes, para 
          recolher informações a partir dos Serviços, para fins comerciais;</p>
        <p>➤ intimidar ou assediar outra pessoa, ou promover materiais sexualmente explícitos, violência ou discriminação 
          com base na raça, sexo, religião, nacionalidade, incapacidade, orientação sexual ou idade;</p>
        <p>➤ utilizar ou tentar utilizar a conta, o serviço ou o sistema de outra pessoa ou criar uma identidade falsa
           nos Serviços;</p>
        <p>➤ utilizar os Serviços de modo a criar um conflito de interesse para a Escola de Numerologia ou o usuário, 
          ou comprometer os fins dos Serviços;</p>
        <p>➤ utilizar os Serviços de forma intencional, deliberada ou negligente para, transmitir, distribuir, 
          armazenar, ou, de outro modo, disponibilizar, por qualquer meio:</p>        
        <p className='itens'>a) materiais que infrinjam, ou possam infringir leis aplicáveis, ou que infrinjam os direitos 
          de qualquer pessoa;</p>
        <p className='itens'>b) publicidade não solicitada ou não autorizada, angariações, materiais promocionais;</p>
        <p className='itens'>c) materiais que infrinjam ou possam infringir qualquer direito de autor, marca comercial 
          ou outro direito de imagem de qualquer outra pessoa;</p>
        <p className='itens'>d) materiais que infrinjam os direitos de privacidade ou de personalidade de qualquer pessoa 
          ou pessoa falecida;</p>
        <p className='itens'>e) materiais que difamem qualquer pessoa ou que sejam obscenos, ofensivos, pornográficos, 
          incitadores ao ódio ou inflamatórios;</p>
        <p className='itens'>f) materiais que constituam, encorajem ou forneçam instruções para a prática de um crime, 
          de atividades perigosas ou de automutilação;</p>
        <p className='itens'>g) materiais deliberadamente concebidos para provocar ou antagonizar as pessoas, especialmente 
          para fins de trolling e bullying, ou que visem assediar, lesar, magoar, assustar, perturbar, envergonhar ou 
          preocupar as pessoas;</p>
        <p className='itens'>h) materiais que contenham qualquer tipo de ameaça, incluindo ameaças de violência física;</p>
        <p className='itens'>i) materiais racistas ou discriminatórios, incluindo a discriminação com base na raça, 
          religião, idade, sexo, incapacidade ou sexualidade de qualquer pessoa;</p>
        <p className='itens'>j) materiais que possam expor a Escola de Numerologia, os Serviços, ou os seus usuários a 
          qualquer tipo de dano ou responsabilidade.</p>
        <p className='after-itens'>A Escola de Numerologia reserva-se o direito de, a qualquer momento e, sem aviso prévio, remover ou desativar 
          temporária, ou permanentemente, o acesso a conteúdos, se a Escola de numerologia, segundo o seu critério exclusivo, 
          considerar que os conteúdos violam ou possivelmente violarem os presentes Termos.</p>

        <strong>9. Conteúdos</strong>
        <p><b>Conteúdos da Escola de Numerologia:</b></p>
        <p>É estritamente proibida a utilização dos Conteúdos da Escola de Numerologia ou de outros materiais incluídos 
          nos Serviços para qualquer fim que não seja expressamente permitido pelos presentes Termos. Tais conteúdos e 
          materiais não podem ser descarregados, copiados, reproduzidos, distribuídos, transmitidos, emitidos por 
          rádio ou televisão, exibidos, vendidos, licenciados ou, de outro modo, explorados, para qualquer fim sem o 
          prévio consentimento por escrito da Escola de Numerologia, reservam-se, absoluta e, incondicionalmente, todos os 
          direitos decorrentes ou resultantes dos Serviços e dos Conteúdos da Escola de Numerologia.</p>
        <p>O Usuário reconhece e aceita que a Escola de Numerologia poderá gerar receitas, ou, de outro modo, aumentar 
          o valor da Escola de Numerologia pela utilização dos Serviços pelo Usuário, incluindo, a título de exemplo e, 
          entre outras coisas, através da venda de publicidade, patrocínios, promoções, mail marketing, dados de utilização 
          e ofertas e, exceto, conforme for especificamente permitido pela Escola de Numerologia nos presentes Termos ou 
          noutro contrato que o Usuário celebrar com a Escola de Numerologia, o Usuário não terá direito a beneficiar 
          dessas receitas, ou valor. O Usuário reconhece que, exceto conforme for especificamente permitido pela 
          Escola de Numerologia nos presentes Termos ou noutro contrato que o Usuário celebrar com a Escola de Numerologia, 
          o Usuário não tem direito a receber qualquer rendimento ou outra contrapartida pelos Conteúdos produzidos dentro 
          da plataforma ou pela utilização disponibilizadas nos Serviços, incluindo os Conteúdos do usuário criado pelo 
          próprio usuário, e está proibido de exercer direitos de monetização ou obtenção de contrapartidas a partir de 
          Conteúdos dos usuários no âmbito dos Serviços, ou de qualquer serviço terceiro (incluindo, entre outros, 
          o YouTube, Facebook, Instagram, Twitter, ou qualquer outra plataforma de redes sociais).</p>
        <p>Sob reserva dos presentes Termos, é por este meio conferida ao Usuário uma licença não exclusiva, limitada, 
          intransmissível, não sublicenciável, revogável e mundial para acessar aos Serviços, e utilizá-los, incluindo 
          para descarregar a Plataforma num dispositivo permitido, e para acessar aos Conteúdos da Escola de Numerologia 
          apenas para fins de utilização pessoal e não comercial do Usuário, através da utilização dos Serviços por este 
          último e apenas em conformidade com os presentes Termos. A Escola de Numerologia reserva-se absoluta e, 
          incondicionalmente, todos os direitos que não sejam aqui expressamente conferidos sobre os Serviços e os 
          Conteúdos da Escola de Numerologia. O Usuário reconhece e aceita que após o cancelamento da sua conta, 
          ou a rescisão dos presentes Termos, esta licença que lhe foi concedida em relação aos Serviços será, 
          automaticamente, cancelada.</p>
        <p>O Usuário reconhece e aceita que quando visualiza conteúdos disponibilizados por outras pessoas nos 
          Serviços, fá-lo por sua própria conta e risco.</p>
        <p>Usuário reconhece que a Escola de Numerologia não tem qualquer obrigação de controlar previamente, 
          monitorizar, analisar ou editar quaisquer conteúdos publicados pelo Usuário e por outros Usuários na 
          Plataforma.</p>
        <p>Nosso Serviço é fornecido “no estado em que se encontra”, e não podemos garantir que ele será seguro e 
          funcionará perfeitamente o tempo todo. Também não controlamos o que as pessoas ou terceiros fazem ou 
          mencionam e não somos responsáveis pelas respectivas (inclusive as suas) condutas ou ações, online ou offline, 
          nem pelo conteúdo (inclusive conteúdo censurável ou ilícito).</p>
        <p>Também não somos responsáveis pelos serviços e recursos oferecidos por outras pessoas ou empresas.</p>
        <p>Nossa responsabilidade por qualquer ocorrência no Serviço será limitada tanto quanto permitido por lei. 
          Não temos como prever todos os impactos possíveis que um problema com nosso Serviço possa causar. Você 
          concorda que nós não seremos responsáveis por qualquer perda de lucro, receitas, informação ou dados, 
          ou, ainda, por danos eventuais, especiais, indiretos, exemplares, punitivos ou acidentais decorrentes de 
          ou relativos a estes Termos, ainda que saibamos que eles são possíveis. Isso se aplica inclusive se nós 
          excluirmos seu conteúdo, informações ou conta.</p>
        <p>O Usuário renuncia (na medida permitida pela lei aplicável) a todos e quaisquer direitos de privacidade, 
          publicidade ou quaisquer outros direitos de natureza análoga, em relação aos seus Conteúdos de Usuário, 
          ou a qualquer parte dos mesmos. O Usuário renuncia por este meio (na medida permitida pela lei aplicável) 
          e aceita nunca reclamar todos e quaisquer direitos morais que possa ter nos Conteúdos do Usuário ou a 
          respeito dos mesmos, que carrega ou que, de outro modo, disponibiliza através dos Serviços, ou que apoia, 
          mantém, ou permitir qualquer ato com base nesses direitos morais.</p>
        <p>O Usuário reconhece e aceita que a Escola de Numerologia, em determinadas circunstâncias tem também o 
          direito de divulgar a identidade do Usuário a qualquer terceiro que alegue que quaisquer Conteúdos do 
          Usuário publicados ou carregados pelo Usuário nos Serviços da Escola de Numerologia constituem uma violação 
          dos seus direito à privacidade.</p>
        <p>A Escola de Numerologia não assume qualquer responsabilidade no que diz respeito a conteúdos submetidos 
          pelos usuários dos Serviços e publicados pela Escola de Numerologia, ou em nome desta, em qualquer um dos 
          Serviços ou em qualquer outro local por terceiros.</p>

        <strong>10. Indenização</strong>
        <p>O Usuário compromete-se a defender, indenizar e isentar de responsabilidade a Escola de Numerologia e empresas 
          afiliadas, e cada um dos seus respectivos responsáveis, administradores, funcionários, agentes e consultores 
          por todas e quaisquer reclamações, obrigações, custos, perdas e danos, e despesas (incluindo, entre outros, 
          as despesas e os honorários de advogados), decorrentes ou resultantes da violação dos presentes Termos 
          pelo Usuário (ou por qualquer usuário da sua conta ou de quaisquer Serviços) dos presentes Termos, incluindo, 
          entre outros a violação das obrigações, declarações e garantias do Usuário.</p>

        <strong>11. Exclusão de garantias</strong>
        <p>Nada do estipulado nos presentes termos afeta os direitos previstos na lei que o usuário:</p>
        <p>a) não possa contratualmente alterar ou renunciar e</p>
        <p>b) que, por lei, sempre lhe assistem enquanto consumidor.</p>
        <p>A Escola de Numerologia não presta qualquer garantia ou declaração ao usuário respeitante aos mesmos. 
          em particular, a Escola de Numerologia não declara nem garante ao usuário que:</p>
        <p>➤ a utilização dos serviços pelo usuário será ininterrupta, pontual, segura ou isenta de erros;</p>
        <p>➤ as videos obtidos pelo usuário em resultado da utilização dos serviços pelo mesmo serão exatos ou isentos de erros; e</p>
        <p>➤ os defeitos no funcionamento ou na funcionalidade de qualquer software que for disponibilizado ao usuário no âmbito dos serviços serão corrigidos.</p>
        <p>na medida permitida pela lei aplicável, não são aplicáveis aos serviços quaisquer condições, garantias, 
          declarações, ou outros termos (incluindo condições implícitas de qualidade satisfatória), exceto na medida em 
          que estejam expressamente previstos nos termos. a Escola de Numerologia pode, em qualquer momento e, sem aviso, 
          alterar, suspender, retirar ou restringir a disponibilidade de toda ou qualquer parte da plataforma da 
          Escola de Numerologia por motivos comerciais e funcionais.</p>

        <h1>Política de Privacidade</h1>
        <p>Temos o compromisso de proteger e respeitar sua privacidade. Esta política explica nossas práticas relativas 
          aos dados pessoais que coletamos de você ou que você nos fornece. Se você não concordar com esta política, 
          não deve usar a Plataforma.</p>

        <strong>Que informações coletamos sobre você?</strong>
        <p>Coletamos e processamos as informações que você nos fornece ao criar uma conta e fazer download de conteúdo 
          da Plataforma.</p>

        <strong>Como vamos usar as informações sobre você?</strong>
        <p>Usamos suas informações para fornecer a Plataforma a você e para melhorá-la, administrá-la e garantir sua 
          segurança.</p>
        <p>Para que não restem dúvidas quanto ao uso dos dados fornecidos pelos usuários, nós administradores e 
          responsáveis por esta plataforma informamos que não compartilhamos, de forma alguma, qualquer informação 
          pessoal que tenha cunho de identificação, com nenhuma pessoa física ou jurídica. EXCETO ao que se refere no 
          parág. 2º do item 9 e parág. 9º do item 9 dos termos de uso, bem como para geração de relatórios internos.</p>

        <strong>Com quem compartilhamos suas informações?</strong>
        <p>Compartilhamos seus dados com provedores de serviços terceirizados que nos ajudam a fornecer a Plataforma, 
          como provedores de armazenamento em nuvem e parceiros comerciais.</p>

        <strong>Por quanto tempo mantemos suas informações?</strong>
        <p>Retemos suas informações pelo tempo necessário para fornecer o serviço.</p>

        <strong>Como deletar os seus dados?</strong>
        <p>Pensando na comodidade de nossos usuários e na transparência de nossos serviços, disponibilizamos um campo 
          no site, reservado para exclusão direta pelo usuário na base de dados da Escola de Numerologia.</p>

        <strong>Como iremos notificá-lo de quaisquer alterações nesta Política de Privacidade?</strong>
        <p>Em geral, notificaremos todos os usuários sobre quaisquer alterações materiais nesta política por meio 
          de um aviso em nossa Plataforma. No entanto, você deve examinar regularmente esta política para verificar 
          se há alterações. Ao acessar ou usar a Plataforma, você reconhece que leu esta política e que
          entende seus direitos em relação aos seus dados pessoais</p>

        <strong>Como usamos seus dados pessoais</strong>
        <p>Usaremos as informações que coletamos sobre você das seguintes maneiras:</p>
        <p>➤ notificá-lo sobre alterações em nosso serviço;</p>
        <p>➤ fornecer suporte ao usuário;</p>
        <p>➤ comunicar com você;</p>
        <p>➤ para nos ajudar a detectar e combater abusos, atividades nocivas, fraudes, e atividades ilegais na Plataforma;</p>
        <p>➤ garantir que o conteúdo seja apresentado da maneira mais eficaz para você e seu dispositivo;</p>
        <p>➤ em campanhas de marketing digital, com o objetivo de criar e manter o relacionamento com os usuários, além de 
          informar os Usuários de lançamentos de conteúdos novos da Escola de Numerologia e de nossos parceiros.</p>
        <p>➤ para garantir que você tenha idade suficiente para usar nossa Plataforma (conforme exigido por lei);</p>
        <p>➤ fazer cumprir nossos termos, condições e políticas; e</p>
        <p>➤ administrar a plataforma, incluindo solução de problemas.</p>

        <strong>Como compartilhamos seus dados pessoais Aplicação da Lei</strong>
        <p>Compartilharemos suas informações com agências de aplicação da lei, autoridades públicas ou outras organizações 
          se for legalmente exigido ou se tal uso for razoavelmente necessário para:</p>
        <p>➤ cumprir obrigação legal, processo ou solicitação;</p>
        <p>➤ fazer cumprir nossos Termos de Serviços e outros acordos, políticas e padrões, incluindo a investigação de 
          qualquer possível violação dos mesmos;</p>
        <p>➤ detectar, impedir ou resolver problemas técnicos, de fraude ou de segurança;</p>

        <h1>Termos Suplementares - Específicos da Jurisdição</h1>
        <p>Se surgir algum conflito entre a Política de Privacidade principal e os Termos Suplementares, os seguintes 
          termos prevalecerão:</p>

        <strong>Consentimento dos pais e responsáveis</strong>
        <p>Se exigido pelas leis brasileiras de proteção de dados, (i) se você tem mais de 16 anos e menos de 18 anos, 
          você só pode usar e registrar uma conta mediante a assistência de seus pais ou responsáveis legais e declara e g
          arante que teve referida assistência para usar os Serviços e concordar com esta Política; (ii) se você tem mais 
          de 13 anos mas menos de 16 anos, você só pode usar e registrar uma conta com a representação de seus pais ou 
          responsáveis legal e deve obter o consentimento de seus pais ou responsáveis legais para o uso dos Serviços e 
          aceitação desta Política de Privacidade.</p>

        <strong>Exercício de direitos de proteção de dados</strong>
        <p>A legislação brasileira concede certos direitos às pessoas com relação aos seus dados pessoais. Assim, buscamos 
          garantir transparência e controles de acesso para permitir que os usuários se beneficiem dos direitos 
          mencionados.</p>
        <p>Responderemos e/ou atenderemos às suas solicitações para o exercício de seus direitos abaixo, de acordo com a 
          lei aplicável e com a Lei Geral de Proteção de Dados - LGPD</p>

        <strong>Verificando sua identidade</strong>
        <p>Para sua segurança e para nos permitir garantir que não divulgamos seus dados pessoais a terceiros não 
          autorizados, a fim de verificar sua identidade e garantir o exercício adequado de seus direitos, podemos 
          solicitar informações específicas e/ou documentos seus antes que possamos responder adequadamente a uma 
          solicitação recebida sobre seus dados. Todos os seus dados e documentos recebidos no processo de 
          resposta às suas solicitações serão usados para os estritos fins de analisar sua solicitação, autenticar 
          sua identidade e, finalmente, responder à sua solicitação.</p>

        <strong>Limitações aos seus direitos</strong>
        <p>Em certas situações, podemos ter razões legítimas para não atender a algumas de suas solicitações. 
          Por exemplo, podemos optar por não divulgar determinadas informações a você quando uma divulgação puder 
          afetar adversamente nossos negócios sempre que houver risco de violação de nossos segredos comerciais 
          ou direitos de imagem. Além disso, podemos nos abster de atender a uma solicitação de exclusão quando a 
          manutenção de seus dados for necessária para cumprir obrigações legais ou regulamentares ou, quando essa 
          manutenção for necessária para proteger nossos direitos e interesses em caso de disputa. Sempre que for 
          esse o caso e não pudermos atender a uma solicitação que você fizer, informaremos os motivos pelos quais 
          não podemos atender à sua solicitação.</p>

        <strong>Registros de acesso</strong>
        <p>Mantemos os registros de acesso ao seu aplicativo, sob sigilo, em um ambiente controlado e seguro, 
          a fim de cumprir obrigações legais.</p>

        <strong>Contato</strong>
        <p>Em caso de dúvida sobre sua privacidade, seus direitos ou como exercê-los, entre em contato conosco 
          através do "Fale Conosco". Se você tiver alguma dúvida sobre o processamento de seus dados pessoais, 
          gostaríamos de esclarecê-la.</p>

      </div>        
    </div>
  );
}

export default PrivacyAndUserTermsPage;
