import { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

interface Props {
  id?: string,
  className?: string,
  name: string,
  label?: string,
  placeholder?: string,
  type?: string,
  value?: string
  disabled?: boolean,
  styles?: React.CSSProperties,  
}

type InputProps = Props
const Input = ({ id, className, name, placeholder, type, value, disabled, styles }: InputProps) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  
  return (   
    <input 
      id={id}
      className={className}
      name={name}
      ref={inputRef} 
      placeholder={error ? error : placeholder} 
      type={type}
      value={value}
      disabled={disabled}      
      style={styles} />       
  );
}

export default Input;
