import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import ReactPlayer from 'react-player'

import api from '../../../services/api';

import ICourseLesson from '../../../data/interfaces/ICourseLesson';

import { useLogHandler } from '../../../contexts/logs';
import { useAuth } from '../../../contexts/auth';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  videoBoxStyle,
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  reactPlayer,
  h3Style,
  buttonBoxStyle,
  buttonStyle,
  invisibleButtonStyle,
  backStrongStyle,
  linkStyle,
} from './styles';

interface Props { 
  courseLesson: ICourseLesson;
}
type props = Props

const CourseLessonVideoPlayer = ({ courseLesson }: props) => {    
  const { createLogCourseLessonVideoView, createLogCourseLessonVideoViewFull } = useLogHandler();
  const { handleRefreshToken } = useAuth();  
  
  useEffect(() => {
    createLogCourseLessonVideoView(String(courseLesson.courseUid), courseLesson.uid);
  }, []);

  const reloadData = async () => {
    try {     
      await api.put('/courses/'+courseLesson.courseUid+'/lessons/'+courseLesson.uid+'/progress');
    } catch (error) {
      console.log('unauthorized')
    }
  };

  const handleFinishedVideo = async () => {
    createLogCourseLessonVideoViewFull(String(courseLesson.courseUid), courseLesson.uid);
    try {     
      await api.put('/courses/'+courseLesson.courseUid+'/lessons/'+courseLesson.uid+'/progress');
    } catch (error: any) {
      if (error.message === 'unauthorized') {
        await handleRefreshToken();
        reloadData();     
      } 
      console.log('unauthorized')
    }
  }

  return (
    <Box sx={mainStyle}>
      <Box component='h3' sx={h3Style}>CURSO DE {courseLesson.courseTitle}</Box>
      <Link to={'/cursos/'+courseLesson.courseUid} style={{ textDecorationColor: '#FFFFFF' }}>
        <Box component='strong' sx={backStrongStyle}>Voltar ao Curso</Box>  
      </Link>                  
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{courseLesson.title}</Box> 
        </Box>
        <Box sx={videoBoxStyle}>
          <ReactPlayer
            style={reactPlayer}
            url={courseLesson.video}
            width='100%'
            height='100%'
            controls
            pip
            light={<img style={imgStyle} src={courseLesson.thumbnail} alt='Thumbnail' />}
            onEnded={handleFinishedVideo}           
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{courseLesson.workload}</Box> 
        </Box>                
      </Box>
      <Box sx={buttonBoxStyle}>
        {
          courseLesson.previousLessonUid ?
            <Link reloadDocument to={'/cursos/'+courseLesson.courseUid+'/aula/'+courseLesson.previousLessonUid} style={{ textDecoration: 'none' }}>
              <Box component='button' sx={buttonStyle}>ANTERIOR</Box>
            </Link>
          :
            <Box component='button' sx={invisibleButtonStyle}></Box>       
        }
        {
          courseLesson.nextLessonUid ?
            <Link reloadDocument to={'/cursos/'+courseLesson.courseUid+'/aula/'+courseLesson.nextLessonUid} style={{ textDecoration: 'none' }}>
              <Box component='button' sx={buttonStyle}>PRÓXIMA</Box>
            </Link>
          :
            <Box component='button' sx={invisibleButtonStyle}></Box>     
        }                 
      </Box>
    </Box>
  );
};

export default CourseLessonVideoPlayer;
