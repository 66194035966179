import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  width: {
    xs: '30rem',
    md: '75rem',
    lg: '85rem',
  },
  height: {
    xs: '35rem',
    md: '20rem',
    lg: '28rem',  
  },      
  display: 'flex',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  justifyContent: {
    xs: 'center',
    md: 'space-between',
  },      
  alignItems: 'center',
  marginTop: {
    xs: '3rem',
    md: '2rem',
  },
  marginBottom: {
    xs: '3rem',
    md: '2rem',
  }, 
};

export const contentStyle: SxProps = {
  width: {
    xs: '30rem',
    md: '35rem',
    lg: '40rem',
  },
  height: {
    xs: '16.87rem',
    md: '20rem',
    lg: '22.85rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
  cursor: 'pointer',  
};

export const titleBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    md: '35rem',
    lg: '40rem',
  },
  height: {
    xs: '18rem',
    md: '16rem',
    lg: '20rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',     
  alignItems: 'center', 
};

export const h1Style: SxProps = {   
  fontSize: {
    xs: '1.4rem',
    md: '1.7rem',
    lg: '2rem'      
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  marginBottom: '1rem',
  textAlign: 'center',    
};

export const imgStyle: SxProps = {
  width: '100%',
  height: '100%',
}

export const buttonBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '5rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const buttonStyle: SxProps = {
  width: {
    xs: '17rem',    
    lg: '22rem',
  },
  height: {
    xs: '3rem',
    lg: '4rem',
  },  
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text-green)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: {
    xs: '1.5rem',    
  },
  fontWeight: 600,
  fontFamily: 'barlow',    
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  '&:hover': { color: 'var(--color-button-text-green-hover)' },    
};

export const checkStyle: SxProps = {
  width: '100%',
  height: {
    xs: '3rem',
  },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer', 
};

export const strongStyle: SxProps = {   
  fontSize: {
    xs: '1.4rem',
    md: '1.7rem',
    lg: '2rem'      
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',  
  textAlign: 'center',
  marginRight: '1rem',   
};

export const modalStyle: SxProps = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { 
    xs: '35rem',
    md: '40rem',
    lg: '50rem',    
  },
  height: {
    xs: '15rem',      
  },    
  background: 'var(--color-background)',
  border: '2px solid #000',
  borderRadius: '2rem',
  borderColor: '#AC6633',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',   
};

export const modalCloseIcon: SxProps = {
  position: 'absolute' as 'absolute',
  top: {
    xs: '1rem',     
  },
  right: {
    xs: '1rem',     
  },   
  width: '2rem',
  height: '2rem',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'var(--color-background-icon)',
  cursor: 'pointer',
};

export const modalStrongStyle: SxProps = {   
  width: '90%',
  fontSize: {
    xs: '1.6rem',
    md: '1.7rem',
    lg: '2rem'      
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',  
  textAlign: 'center',
  marginRight: '1rem',   
};
