const error = { 
  INVALID_EMAIL: 'invalid-email',
  TOO_MANY_REQUESTS: 'too-many-requests',
  UNEXPECTED_DB_ERROR: 'unexpected-database-error',
  UNEXPECTED_ERROR: 'unexpected-error',
  USER_NOT_FOUND: 'user-not-found',
  EMAIL_ALREADY_IN_USE: 'email-already-in-use',
  EMAIL_NOT_VERIFIED: 'email-not-verified',
  WRONG_PASSWORD: 'wrong-password',
  INVALID_REQUEST: 'invalid-request',
  INVALID_CREDENTIAL: 'invalid-credential',
  LESSON_IS_NOT_FREE: 'lesson-is-not-free',
  CREATE_CHECKOUT_ERROR: 'create-checkout-error',
  BLOCKED_ORDER: 'blocked-order',
  AVATAR_FILE_TYPE_NOT_ACCEPTED: 'FileTypeError',
};

export default error;
