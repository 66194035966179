import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  flexGrow: 1,      
  display: 'flex',
  flexDirection: 'column',      
  alignItems: 'center', 
};

export const contentStyle: SxProps = {
  width: { xs: '40rem', md:'100rem' },
  height: {
    flexGrow: 1,     
  },
  minHeight: '40rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginBottom: '4rem',
  marginTop: '2rem',
};

export const h3Style: SxProps = {
  fontSize: '3rem',
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textShadow: '2px 2px var(--color-text-dark-gray)',
  marginTop: '3rem',
  marginBottom: '3rem',        
};
