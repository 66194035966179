import React, { useState, useEffect } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid/Grid';
import Loader from "react-js-loader";

import { useAuth } from '../../../contexts/auth';

import api from '../../../services/api';

import ICourse from '../../../data/interfaces/ICourse';

import Course from './Course';

import {
  mainStyle, 
  h3Style,
  contentStyle,  
} from './styles';

const CoursesList: React.FC = () => {
  const [ courses, setCourses ] = useState<Array<any>|[]>([]); 
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {      
      const response = await api.get('/courses');
      if (response.data) {
        setCourses(response.data.courses);       
      }      
    } catch (error) {
      console.log('unauthorized')
    }
  };

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/courses');        
        if (response.data) {          
          setCourses(response.data.courses);         
        }            
      } catch (error: any) {       
        if (error.message === 'unauthorized') await handleRefreshToken();
        reloadData();                      
      }     
    }    
    loadData();    
  }, []); 
  
  return (
    <Box sx={mainStyle}>
      <Box component='h3' sx={h3Style}>CURSOS PARA INICIANTES</Box>      
      <Box sx={contentStyle}>
      {
          courses.length > 0 ? 
          <Grid container spacing={{ xs: '2rem', lg: '4rem' }} justifyContent='center' alignItems='center'>
            {courses.map((c: ICourse) => {             
              return (                
                <Grid  xs='auto' md='auto' lg='auto' key={c.uid}>                  
                  <Course course={c} key={c.uid}/>                               
                </Grid>
              )
            })}
          </Grid> : 
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />    
        }  
      </Box>
    </Box>
  );
}

export default CoursesList;
