import React from 'react';
import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import SignUpEmail from '../../components/PageComponents/SignUpEmail';
import Footer from '../../components/PageComponents/Footer';

import './styles.css';

const SignUpPage: React.FC = () => {
  
  const gaEventTracker = useAnalyticsEventTracker('Registrar-com-email Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/registrar-com-email', 'Registrar-com-email Page');
  }, []);
  
  return (
    <div id="signup-page" className="container">     
      <SignUpEmail />
      <Footer />          
    </div>
  );
}

export default SignUpPage;
