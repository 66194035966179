import React, { createContext, useContext, useState } from "react";

import IUser from "../data/interfaces/IUser";
import IUserProfile from "../data/interfaces/IUserProfile";

import { useAuth } from '../contexts/auth';

import api from '../services/api';

interface AppDataContextData {
  navigateTo: string,
  loader: boolean,
  user: IUser,
  userProfile: IUserProfile,
  setRouteTo(route: string): void,
  loadMeData(): Promise<boolean>,
  resetUser(): void,
  deleteUserAccount(): Promise<void>,
}
type Props = {
  children?: React.ReactNode
};

const AppDataContext = createContext<AppDataContextData>({} as AppDataContextData);

export const AppDataProvider: React.FC<Props> = ({ children }) => {
  const [ navigateTo, setNavigateTo ] = useState<string>('/'); 
  const [ loader, setLoader ] = useState<boolean>(false);
  const [ user , setUser ] = useState<IUser>({} as IUser);
  const [ userProfile, setUserProfile ] = useState<IUserProfile>({} as IUserProfile);  
  
  const { handleRefreshToken } = useAuth();

  const setRouteTo = (route: string) => {
    const routes = route.split('/');
    const PAGE_ROUTES = [ 
      '', 
      'mentorias', 
      'cursos', 
      'pedidos', 
      'perfil', 
      'comunidade', 
      'duvidas', 
      'termos-de-uso-e-privacidade', 
      'cadastro', 
      'perguntas-mais-frequentes',
    ];

    PAGE_ROUTES.map((r) => {
      if (routes[1] === r) setNavigateTo(route);      
    });    
  };

  const loadMeData = async () => { 
    try {
      setLoader(true);
      const response = await api.get('/users/getMe');        
      if (response.data) {
        setUser(response.data.user);
        setUserProfile(response.data.profile);                      
      }
      setLoader(false);
      return false;       
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      try {      
        const response: any = await api.get('/users/getMe');
        if (response.data) {
          setUser(response.data.user);
          setUserProfile(response.data.profile);             
        }
        setLoader(false);
        return false;     
      } catch (error) {
        console.log('unauthorized')
        return false;
      }                      
    }     
  };

  const resetUser = () => {
    setUser({} as IUser);
    setUserProfile({} as IUserProfile);
  };

  const deleteUserAccount = async () => {
    try {
      setLoader(true);
      const response = await api.delete('/users');      
      setLoader(false);
      return;       
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      try {      
        const response: any = await api.delete('/users');       
        setLoader(false);
        return;     
      } catch (error) {
        console.log('unauthorized')
        return;
      }                      
    }  
  };

  return (
    <AppDataContext.Provider value={{ navigateTo, loader, user, userProfile, setRouteTo, loadMeData, resetUser, deleteUserAccount }}>     
      {children}
    </AppDataContext.Provider>
  );
};

export const useAppDataHandler = () => {
  const context = useContext(AppDataContext);
  return context;
};
