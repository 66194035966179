import axios from 'axios';

const BASE_URL = 'https://api.escoladenumerologia.com.br';
//const BASE_URL = 'https://escola-de-numerologia-backend-dev-k4ug74lpbq-uc.a.run.app';

const api = () => {
  const instance = axios.create({
    baseURL: BASE_URL,     
    timeout: 60000,   
  });

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response.status === 401) {
        throw new Error('unauthorized');
      }     

      if (err.response.data.error) {               
        throw new Error(err.response.data.error);        
      }
    },
  );

  return instance;
};

export default api();

export const sendEmailQuestions = async (
  senderName: string,
  senderEmail: string,
  subject: string,
  message: string
) => {
  try {
    await axios({
      method: 'POST',
      url: BASE_URL+'/form/questions/send',
      data: {
        senderName,
        senderEmail,
        subject,
        message,
      },
    });
    return;
  } catch (error) {    
    return;
  }
};
