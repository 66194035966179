import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import { MdClear } from "react-icons/md";
import ReactPlayer from 'react-player'

import api from '../../../services/api';

import ILesson from '../../../data/interfaces/ILesson';

import { useLogHandler } from '../../../contexts/logs';
import { useAuth } from '../../../contexts/auth';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  videoBoxStyle,
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,
  linkStyle,
  modelStyle,
  modalCloseIcon,
  reactPlayer,
  attachmentsStyle,
  attachmentUrlStyle,
} from './styles';

interface Props { 
  lesson: ILesson;
}
type props = Props

const FOLHA_URL = 'https://folha.truenumbers.com.br';

const LessonVideoPlayer = ({ lesson }: props) => {    
  const [ open, setOpen ] = useState(false);
  
  const { handleRefreshToken } = useAuth();
  const { createLogLessonVideoView, createLogLessonVideoViewFull } = useLogHandler();

  useEffect(() => {
    createLogLessonVideoView(lesson.uid);
  }, []);

  const handleOpen = () => { 
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  };

  const reloadData = async () => {
    try {     
      await api.put('/lessons/'+lesson.uid+'/progress');
    } catch (error) {
      console.log('unauthorized')
    }
  };

  const handleFinishedVideo = async () => {
    createLogLessonVideoViewFull(lesson.uid);
    try {     
      await api.put('/lessons/'+lesson.uid+'/progress');
    } catch (error: any) {
      if (error.message === 'unauthorized') {
        await handleRefreshToken();
        reloadData();     
      } 
      console.log('unauthorized')
    }
  }

  return (
    <Box sx={mainStyle}>       
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="support-material"
        aria-describedby="support-material"
        disableScrollLock={true}      
      > 
        <Box sx={modelStyle}>
          <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
          <Box component='strong'>Material anexo:</Box>
          <Box sx={attachmentsStyle}>
            {
              lesson.attachments && lesson.attachments.length > 0 ?
                lesson.attachments.map((a: any) => {
                return (
                  <Box component='a' sx={attachmentUrlStyle} key={a.title} href={a.url} target='_blank' rel='noreferrer noopener'>{a.title}</Box> 
                )})               
              :
                <Box component='p'>Nada foi disponibilizado</Box>
            }                                     
          </Box>
        </Box>
      </Modal>      
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{lesson.title}</Box> 
        </Box>
        <Box sx={videoBoxStyle}>
          <ReactPlayer
            style={reactPlayer}
            url={lesson.video}
            width='100%'
            height='100%'
            controls
            pip
            light={<img style={imgStyle} src={lesson.thumbnail} alt='Thumbnail' />}
            onEnded={handleFinishedVideo}   
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{lesson.workload}</Box> 
        </Box>
        <Box component='p' sx={descriptionStyle}>{lesson.description}</Box>
        {
          lesson.value > 0 &&
          <Box sx={buttonBoxStyle}>
            <Box component='a' sx={linkStyle} href={lesson.urlEbook} target='_blank' rel='noreferrer noopener'>
              <Box component='button' sx={buttonStyle} type='button'>MANUAL DE NUMEROLOGIA</Box>
            </Box>
            <Box component='a' sx={linkStyle} href={FOLHA_URL} target='_blank' rel='noreferrer noopener'>
              <Box component='button' sx={buttonStyle} type='button'>PROGRAMA DE CÁLCULO</Box>
            </Box>          
            <Box component='button' sx={buttonStyle} type='button' onClick={handleOpen}>MATERIAL DE APOIO</Box>
            <Link to={'/duvidas'} style={{ textDecoration: 'none' }}>  
              <Box component='button' sx={buttonStyle} type='button'>ENVIE SUAS DÚVIDAS!</Box>
            </Link>          
          </Box>
        }        
      </Box>
    </Box>
  );
};

export default LessonVideoPlayer;
