import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import UserPanel from '../../components/PageComponents/UserPanel';
import CourseLessonVideoPlayer from '../../components/PageComponents/CourseLessonVideoPlayer';
import Footer from '../../components/PageComponents/Footer';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import ICourseLesson from '../../data/interfaces/ICourseLesson';

import {
  mainStyle,
  contentStyle,
  h2Style,
} from './styles';

const CourseLessonPage = () => {
  const [ courseLesson, setCourseLesson ] = useState<ICourseLesson|null>(null);
  const [ loader, setLoader ] = useState(true);
  
  let { courseUid, courseLessonUid } = useParams();
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {     
      const response = await api.get('/courses/'+courseUid+'/lessons/'+courseLessonUid);
      if (response.data) {
        setCourseLesson(response.data.courseLesson);
        setLoader(false);
      }      
    } catch (error) {
      console.log('unauthorized')
    }
  };

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/courses/'+courseUid+'/lessons/'+courseLessonUid);
        if (response.data) {
          setCourseLesson(response.data.courseLesson);
          setLoader(false);
        }              
      } catch (error: any) {
        if (error.message === 'unauthorized') {
          await handleRefreshToken();
          reloadData();     
        } 
        setLoader(false);      
      }     
    }    
    loadData();    
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      {
        courseLesson ?
          <CourseLessonVideoPlayer courseLesson={courseLesson}/>        
        :        
        loader ?
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
        :
          <Box sx={contentStyle}>
            <Box component='h2' sx={h2Style}>Aula não encontrada!</Box>
          </Box>  
      }
      <Footer />
    </Box>
  );
}

export default CourseLessonPage;
