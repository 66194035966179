import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '18rem',
    sm: '21rem', 
    md: '25rem', 
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',      
  alignItems: 'center', 
};

export const headerStyle: SxProps = {
  width: '100%',
  height: {
    xs: '10rem',
    sm: '12rem',    
    md: '15rem',
  }, 
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center', 
};

export const logoStyle: SxProps = {
  width: {
    xs: '12rem',
    sm: '12rem',    
    md: '24rem',
  },
  height: {
    xs: '9rem',      
    md: '12rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',    
  marginLeft: '0.5rem',  
};

export const userMenuStyle: SxProps = {
  width: {
    xs: '12rem',
    sm: '12rem',
    md: '24rem',      
  },
  height: {
    xs: '9rem',      
  },
  display: 'flex',
  flexDirection: {
    xs: 'row',      
  },
  justifyContent: 'flex-end',
  alignItems: 'center',    
  cursor: 'pointer',  
  marginRight: '0.5rem', 
};

export const imgStyle: SxProps = {
  width: {
    xs: '12rem',      
    md: '20.04rem',
  },
  height: {
    xs: '7.18rem',      
    md: '12rem',
  },
};

export const avatarStyle: SxProps = {
  width: {
    xs: '5rem',     
    md: '7rem',
  },
  height: {
    xs: '5rem',     
    md: '7rem',
  },
  //borderRadius: '5rem',
  //objectFit: 'cover',
  marginRight: {
    xs: '0.5rem',
    md: '3rem',
  },
};

export const h1Style: SxProps = {
  fontSize: {
    xs: '1.6rem',
    sm: '2.4rem',
    lg: '3.2rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: '#FFFFFF',         
};

export const separatorStyle: SxProps = {
  width: '100%',
  height: {
    xs: '0.7rem',
    lg: '0.8rem',
  },  
  backgroundImage: 'linear-gradient(to right, var(--color-background-light), var(--color-background-login-dark))',    
};

export const linkStyle: SxProps = {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const modalStyle: SxProps = {
  position: 'absolute' as 'absolute',
  top: {
    xs: '1rem',     
  },
  right: {
    xs: '2rem',      
  },   
  width: { 
    xs: '28rem',     
  },
  height: {
    xs: '35rem',      
  },    
  background: 'var(--color-background)',
  border: '2px solid #000',
  borderRadius: '2rem',
  borderColor: '#AC6633',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',   
};

export const modalButtonBoxStyle: SxProps = {
  height: { 
    xs: '15rem',      
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',      
  alignItems: 'center',    
};

export const modalButtonStyle: SxProps = {
  width: {
    xs: '17rem',     
  },
  height: {
    xs: '3rem',     
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text-black)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: {
    xs: '1.2rem',      
  },
  fontWeight: 600,
  fontFamily: 'barlow',    
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  '&:hover': { color: 'var(--color-button-text-black-hover)' },    
};

export const modalAvatarBox: SxProps = {
  height: { 
    xs: '16rem',      
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',      
  alignItems: 'center',    
};

export const modalAvatarStyle: SxProps = {
  width: {
    xs: '10rem',      
  },
  height: {
    xs: '10rem',     
  },
  cursor: 'pointer',
  borderRadius: '5rem',
  objectFit: 'cover',   
};

export const modalH1Style: SxProps = {
  fontSize: {
    xs: '2rem',      
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',          
};

export const modalStrongStyle: SxProps = {
  fontSize: {
    xs: '1.2rem',      
  },
  fontWeight: 400,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',          
};

export const modalCloseIcon: SxProps = {
  position: 'absolute' as 'absolute',
  top: {
    xs: '1rem',     
  },
  right: {
    xs: '1rem',     
  },   
  width: '2rem',
  height: '2rem',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'var(--color-background-icon)',
  cursor: 'pointer',
};

export const modalAvatarUpdateIcon: SxProps = {
  position: 'absolute' as 'absolute',
  top: {
    xs: '9rem',     
  },
  right: {
    xs: '9rem',     
  },   
  width: '2rem',
  height: '2rem',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'var(--color-background-icon)',
  cursor: 'pointer',
  zIndex: 1,
};
