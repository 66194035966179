import { format } from 'date-fns';

import orderStatus from '../data/enums/orderStatus';
import paymentMethod from '../data/enums/paymentMethod';

const OrderUtils = () => {

  const handleDateHour = (data: any) => {
    try {
      const updatedAt: any = data;    
      return format(updatedAt, 'dd/MM/yyyy HH:mm');
    } catch (error) {

    }   
  };

  const handleDateHourSeconds = (data: any) => {
    try {
      const updatedAt: any = data;      
      return format(updatedAt, 'dd/MM/yyyy HH:mm:ss');
    } catch (error) {
      
    }    
  };

  const handleStatus = (status: string) => {    
    switch (status) {
      case orderStatus.AUTHORIZED:
        status = 'Autorizado';
        break;
      case orderStatus.CANCELED:
        status = 'Cancelado';
        break;
      case orderStatus.CREATED:
        status = 'Iniciado';
        break;
      case orderStatus.DECLINED:
        status = 'Negado';
        break;
      case orderStatus.IN_ANALYSIS:
        status = 'Em analise';
        break;
      case orderStatus.PAID:
        status = 'Pago';
        break;
      case orderStatus.WAITING:
        status = 'Aguardando';
        break;
      case orderStatus.COURTESY:
        status = 'Cortesia';
        break;
    }
    return status;    
  };

  const handlePaymentMethod = (method: string) => {   
    switch (method) {
      case paymentMethod.CREDIT_CARD:      
        method = 'Crédito';
        break;
      case paymentMethod.DEBIT_CARD:
        method = 'Débito';
        break;
      case paymentMethod.BOLETO:
        method = 'Boleto';
        break;
      case paymentMethod.PIX:
        method = 'Pix';
        break;
      case paymentMethod.FREE:
        method = 'Cortesia';
        break;
      case paymentMethod.PAG_BANK:
        method = 'PagBank';
        break;      
      default:
        method = '- - -';
        break;    
    }
    return method; 
  };
  
  return {
    handleDateHour,
    handleDateHourSeconds,
    handleStatus,
    handlePaymentMethod,
  };
};

export default OrderUtils;
