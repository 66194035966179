import React, { createContext, useContext } from "react";
import { useAlert } from 'react-alert'

import typeError from '../data/enums/error';

interface ErrorContextData { 
  handleError(error: string): void,
}
type Props = {
  children?: React.ReactNode
};

const ErrorContext = createContext<ErrorContextData>({} as ErrorContextData);

export const ErrorProvider: React.FC<Props> = ({ children }) => {
  const alert = useAlert()

  const handleError = (error: string) => {    
    switch (error) {
      case typeError.USER_NOT_FOUND:
        alert.show('Usuário não encontrado!', { type: 'error' });
        break;
      case typeError.WRONG_PASSWORD:
        alert.show('Senha incorreta!', { type: 'error' });
        break;
      case typeError.INVALID_EMAIL:
        alert.show('Email não encontrado!', { type: 'error' });
        break;
      case typeError.EMAIL_ALREADY_IN_USE:
        alert.show('Email já cadastrado!', { type: 'error' });
        break;
      case typeError.EMAIL_NOT_VERIFIED:
        alert.show('Email não verificado. Você receberá novo link para verificação em seu email cadastrado!', { type: 'error', timeout: 10000 });
        break;
      case typeError.INVALID_CREDENTIAL:
        alert.show('Erro ao logar com Google!', { type: 'error' });
        break;
      case typeError.LESSON_IS_NOT_FREE:
        alert.show('Aula não gratuita!', { type: 'error' });
        break;
      case typeError.CREATE_CHECKOUT_ERROR:
        alert.show('Erro ao processar o pedido. Tente novamente...', { type: 'error' });
        break;
      case typeError.BLOCKED_ORDER:
          alert.show('Pedido em processamento! Aguarde finalização...', { type: 'error' });
          break;
      case typeError.AVATAR_FILE_TYPE_NOT_ACCEPTED:
          alert.show('Tipo de arquivo não suportado!', { type: 'error', position: 'middle' });
          break;
      case typeError.INVALID_REQUEST:
        alert.show('Requisição inválida!', { type: 'error' });
        break;
      case typeError.TOO_MANY_REQUESTS:
        alert.show('Erro. Muitas requisições simultâneas!', { type: 'error' });
        break;
      case typeError.UNEXPECTED_DB_ERROR:
        alert.show('Erro no banco de dados!', { type: 'error' });
        break;
      case typeError.UNEXPECTED_ERROR:
        alert.show('Erro inesperado!', { type: 'error' });
        break;      
      default:
        alert.show('Erro!', { type: 'error' });
    }
  };

  return (
    <ErrorContext.Provider value={{ handleError }}>     
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorHandler = () => {
  const context = useContext(ErrorContext);
  return context;
};