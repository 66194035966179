import React, { useEffect, useState } from 'react';
import  { Navigate, useRouteError } from 'react-router-dom';
import Loader from "react-js-loader";

import { useAppDataHandler } from '../../contexts/appData';

import './styles.css';

const LoaderPage: React.FC = () => {   
  const [navigate, setNavigate] = useState<boolean>(false);  
  const error: any = useRouteError();
  const { setRouteTo } = useAppDataHandler(); 

  useEffect(() => {
    const route = error.data.split('"')[1];    
    setRouteTo(route);   
    const timeoutId = setTimeout(() => {
      setNavigate(true);
    }, 1000);
  }, []);

  return (
    <div id="loader-page" className="container">
      <div id="loader">
        <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={150} />
        {
          navigate &&
          <Navigate to='/' replace={true} />
        }        
      </div>     
    </div>
  );
}

export default LoaderPage;
