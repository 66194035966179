import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import UserPanel from '../../components/PageComponents/UserPanel';
import LessonsList from '../../components/PageComponents/LessonsList';
import Footer from '../../components/PageComponents/Footer';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import { mainStyle } from './styles';

const LessonsListPage: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Mentorias Page');

  useEffect(() => {
    gaEventTracker.sendPageView('/mentorias', 'Mentorias Page');   
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />
      <LessonsList />
      <Footer />
    </Box>
  );
}

export default LessonsListPage;
