import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import UserPanel from '../../components/PageComponents/UserPanel';
import CourseOrder from '../../components/PageComponents/CourseOrder';
import Footer from '../../components/PageComponents/Footer';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import ICourse from '../../data/interfaces/ICourse';

import {
  mainStyle,
  contentStyle,
  h2Style,
} from './styles';
import CourseLessons from '../../components/PageComponents/CourseLessons';

const CoursePage = () => {
  const [ course, setCourse ] = useState<ICourse|null>(null);
  const [ loader, setLoader ] = useState(true);
  
  let { courseUid } = useParams();
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {     
      const response = await api.get('/courses/'+courseUid);
      if (response.data) {
        setCourse(response.data.course);
        setLoader(false);
      }      
    } catch (error) {
      console.log('unauthorized')
    }
  };

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/courses/'+courseUid);
        if (response.data) {
          setCourse(response.data.course);
          setLoader(false);
        }           
      } catch (error: any) {
        if (error.message === 'unauthorized') {
          await handleRefreshToken();
          reloadData();     
        } 
        setLoader(false);      
      }     
    }    
    loadData();    
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      {
        course ?
          course.isAcquired ?
            <CourseLessons course={course}/>
          :
            <CourseOrder course={course}/>            
        :        
        loader ?
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
        :
          <Box sx={contentStyle}>
            <Box component='h2' sx={h2Style}>Curso não encontrado!</Box>
          </Box>  
      }
      <Footer />
    </Box>
  );
}

export default CoursePage;
