import { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";
import { Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

import { TextInput } from '../../../components/Form';

import { useAuth } from '../../../contexts/auth';
import { useErrorHandler } from "../../../contexts/error";

import logo from '../../../assets/images/logos/novo_logo.png';
import secureIcon from '../../../assets/images/icons/secure.png';
import fotoIngrid from '../../../assets/images/fotos/ingrid.png';
import assinatura from '../../../assets/images/assinatura.png';

import './styles.css';

interface FormData {
  email: string,
  password: string,
}

const SignIn = () => {
  const formRef = useRef<FormHandles>(null);  
  const [loader, setLoader] = useState(false);  
  
  const { handleSignInEmail, handleSignInGoogle } = useAuth();
  const { handleError } = useErrorHandler();  
  
  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({                
        email: Yup.string()
          .email('Digite um email válido')
          .required('Email é obrigatório'),
        password: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Senha é obrigatória'),       
      });
      await schema.validate(data, {
        abortEarly: false,
      });      
      await handleSignInEmail(data.email, data.password);
      formRef.current?.setErrors({});
      reset();
      setLoader(false);     
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);
        });
        formRef.current?.setErrors(validationErrors);              
      }
      setLoader(false);      
    }   
  };

  const handleGoogle = async (codeResponse: any) => {
    try {      
      setLoader(true);
      const { credential } = codeResponse;      
      await handleSignInGoogle(credential, '');     
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };  

  return (
    <div className="signin">
      <div className="signin-content">        
        <div className="signin-box">
          {
            loader &&
            <div id="loader">
              <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={100} />
            </div> 
          } 
          <div className="form-logo">
            <div className='logo'>
              <a href="https://escoladenumerologia.com.br" target='_blank' rel='noreferrer noopener' onClick={() => {} }>
                <img src={logo} alt='logo'/>
              </a>
            </div>
            <strong>Seja bem-vindo(a), faça seu login</strong>
          </div>
          <div className="form-separator" />
          <Form ref={formRef} onSubmit={handleSubmit} placeholder=''>           
            <TextInput className='signin-input' name="email" placeholder="Seu email" type='email' />            
            <TextInput className='signin-input' name="password" placeholder="Sua senha" type='password' />
            <Link to='/recuperar-senha'>
              <strong>Esqueceu a senha?</strong>
            </Link>                              
            <button type="submit">Entrar com email</button>                                         
          </Form>
          <Link id='link-button' to='/registrar-com-email'>
            <button>Cadastre-se com email</button>    
          </Link>
          <div className='google'>
            <GoogleLogin theme='filled_blue' size='large' onSuccess={(codeResponse) => { handleGoogle(codeResponse) }} onError={() => { handleError('invalid-credential') }} />
          </div>                       
          <div className="secure-info">
            <img src={secureIcon} alt='icone_de_seguranca'/>
            <Link to='/termos-de-uso-e-privacidade'>
              <strong>Seus dados estão protegidos</strong>
            </Link>            
          </div>
        </div> 
        <div className="main-box">                 
          <div className='ingrid-foto'>
            <img src={fotoIngrid} alt='foto_ingrid'/>
          </div>
          <strong>Paz e Harmonia!</strong>
          <p>À medida que nos preparamos para embarcar nesta jornada emocionante juntos,
            gostaria de expressar minha imensa alegria e gratidão por estar aqui hoje. É com o
            coração transbordando de entusiasmo que compartilho com vocês um
            conhecimento que adquiri ao longo de mais de 40 anos de experiência na área.</p>
          <p>Cada momento dedicado a entender os segredos da Numerologia tem sido uma
            aventura única e enriquecedora, e agora tenho a honra e a felicidade de passar
            adiante tudo o que aprendi. Este curso não é apenas uma oportunidade de
            aprender, mas também uma chance de crescer juntos, de nos inspirarmos
            mutuamente e de nos conectarmos através do maravilhoso universo dos números.</p>
          <p>Que cada aula seja um mergulho profundo na sabedoria dos números,
            trazendo insights valiosos, autoconhecimento renovado e uma sensação de
            empoderamento para seguir em frente. Que possamos nos apoiar, encorajar
            e celebrar cada passo dado nesta jornada, sabendo que estamos
            caminhando juntos rumo à realização de nossos sonhos e objetivos.</p>
          <p>Estou aqui para guiá-los com todo o carinho, dedicação e conhecimento
            acumulado ao longo dos anos. Vamos aprender, crescer e brilhar juntos!
            Com amor e gratidão,</p>
          <div className='ingrid-signature'>
            <img src={assinatura} alt='assinatura_ingrid'/>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default SignIn;
